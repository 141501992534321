import { useEffect, useRef, useState } from "react";
import { Alert, ButtonPrimario, ButtonSecundario, Contenedor, ContenedorBotones, ContenedorBotonesExterior, ContenedorSection, DivTitulos, DivTitulosSubTitulo, DivTitulosTitulo, EliminarRow, Flex1, Flex3, FlexColumn, FlexInlineMarginTextLabel, FlexRow, InputGrande, InputMini, LabelEliminar, Obligatorio, PEliminar, SecondButtonStyleList, TextAreaGrande, TextDiv, TextLabel, TextLabelTitle, TextoNormal } from "../../../../communStyled";
import { Checkbox, ChevronTabIcon, DomicilioFiscal, Dropdown, Modal, PhoneInput, QuantityManager, SortingTable, Switch, Tabs, Warning } from "../../../../../Components";
import { Subject } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import { ButtonDiv, ContenedorNominaInferior, ContenedorNumeroDeNomina, ContenedorSectionInner, ContenedorSteps, DivFotoContainer, DivNominaTop, DivNominaTopContainer, DivNominaTopLeft, DivNominaTopRight, DivUserFoto, InputGrandeNomina, Inputfile, NominaContenedorTabs, SideButtonContainer, SiglasDiv, Step, TextLabelNomina, Titulo, TopArrowsDiv, TopContainer } from "./NuevoEmpleadoStyled";
import { ccHoursBlur, ccHoursChange, ccNumberLimit, deepCopy, formatDay, formatDayInner, getDifferenceInDays, getRandomID, getUniqueElements, obtenerAñoDeNacimiento, presentarNumero, scrollIntoView } from "../../../../../Utils/sharedFunctions";
import useViewPortSize from "../../../../../Hooks/useViewPortSize";
import Resizer from "react-image-file-resizer";
import NoUser from "./../../../../../assets/img/NoUser.png";
import DatePickerComponent from "../../../../../Components/DatePicker/DatePicker";
import { faChevronCircleLeft, faChevronCircleRight, faClock, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trabajadorContrato, horarios } from "../../../../../types";
import { apiRequest, apiRoutes } from "../../../../../Api/services";
import actions from "../../../../../Redux/Actions";
import styled from './NuevoEmpleado.module.css';
import paths from "../../../../../paths";
import { useNavigate } from "react-router-dom";
import { entidadesFederativasLiteral } from "../../../../../Utils/constants";

export default function NuevoEmpleado() {
  const alertRef = useRef(null);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { smallViewPort } = useViewPortSize();
  const empresa: any = useSelector((store: any) => store.empresa);
  const currentEmpresa: any = useSelector((store: any) => store.currentEmpresa);
  const informacionGeneral: any = useSelector((store: any) => store.informacionGeneral);
  const currentTrabajador: any = useSelector((store: any) => store.currentTrabajador);
  const [step, setStep] = useState(1);
  const [currentTrabajadorCopy, setCurrentTrabajadorCopy] = useState(deepCopy(currentTrabajador));
  const [numeroDeNomina, setNumeroDeNomina] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.info.numeroDeNomina : "");
  const [tabOpenDatosPersonales, setTabOpenDatosersonales] = useState(false);
  const messageSubject = new Subject<string>();
  const warningSubject = new Subject<string>();
  const successfulSubject = new Subject<string>();
  const warningfulSubjectLLenado = new Subject<string>();
  const domicilioFiscalFormRef = useRef<{
    cleanSubjects: any;
    patchValuesFunction: any;
    setCPValid: any;
  }>(null);

  const [curp, setCURP] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.curp : "");
  const [curpIsInvalid, setCURPIsInvalid] = useState(false);
  const [curpIsTouched, setCURPIsTouched] = useState(false);
  const [curpLengthIsInvalid, setCURPlengthIsInvalid] = useState(false);
  const [rfc, setRFC] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.rfc : "");
  const [rfcIsInvalid, setRFCIsInvalid] = useState(false);
  const [rfcIsTouched, setRFCIsTouched] = useState(false);
  const [rfcLengthIsInvalid, setRFClengthIsInvalid] = useState(false);
  const [apellidoPaterno, setApellidoPaterno] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.apellidoPaterno : "");
  const [apellidoPaternoValid, setApellidoPaternoValid] = useState(true);
  const [apellidoMaterno, setApellidoMaterno] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.apellidoMaterno : "");
  const [apellidoMaternoValid, setApellidoMaternoValid] = useState(true);
  const [nombre, setNombre] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.nombre : "");
  const [nombreValid, setNombreValid] = useState(true);
  const [userImage, setUserImage] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.userImage : null);
  const [correoElectronico, setCorreoElectronico] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.correoElectronico : "");
  const [isCorreoElectronicoValid, setIsCorreoElectronicoValid] = useState(true);
  const [telefonoMovil, setTelefonoMovil] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.telefonoMovil : "");
  const [fechaDeNacimiento, setFechaDeNacimiento] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.fechaDeNacimiento : "");
  const [sexo, setSexo] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.sexo : "");
  const [lugarDeNacimiento, setLugarDeNacimiento] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.lugarDeNacimiento : "");
  const [entidadFederativa, setEntidadFederativa] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.entidadFederativa : "");
  const [nacionalidad, setNacionalidad] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.nacionalidad : "");
  const [tipoDePension, setTipoDePension] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.tipoDePension : "Sin pensión");
  const [escolaridad, setEscolaridad] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.escolaridad : "Ninguna");
  const [estadoCivil, setEstadoCivil] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.estadoCivil : "Soltero(a)");
  const [numeroDelIMSS, setNumeroDelIMSS] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.numeroDelIMSS : "");
  const [permitirLlenado, setPermitirLlenado] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.permitirLlenado : false);
  const [tipoDeLlenado, setTipoDeLlenado] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.datosPersonales.datosPersonales.tipoDeLlenado : "Marcado de entradas y salidas");
  const [domicilioFiscalForm, setDomicilioFiscalForm] = useState<any>(currentTrabajadorCopy && currentTrabajadorCopy.datosPersonales.datosPersonales.domicilioFiscal ? currentTrabajadorCopy.datosPersonales.datosPersonales.domicilioFiscal : {
    codigoPostal: currentTrabajadorCopy?.datosPersonales.domicilioFiscal.codigoPostal || "",
    municipio: currentTrabajadorCopy?.datosPersonales.domicilioFiscal.municipio  || "",
    estado: currentTrabajadorCopy?.datosPersonales.domicilioFiscal.estado  || "",
    colonia: currentTrabajadorCopy?.datosPersonales.domicilioFiscal.colonia || "",
    calle: currentTrabajadorCopy?.datosPersonales.domicilioFiscal.calle || "",
    numeroExterior: currentTrabajadorCopy?.datosPersonales.domicilioFiscal.numeroExterior || "",
    numeroInterior: currentTrabajadorCopy?.datosPersonales.domicilioFiscal.numeroInterior || "",
  });

  useEffect(() => {
  domicilioFiscalFormRef.current?.patchValuesFunction({
    codigoPostal: domicilioFiscalForm?.codigoPostal || "",
    municipio: domicilioFiscalForm?.municipio  || "",
    estado: domicilioFiscalForm?.estado  || "",
    colonia: domicilioFiscalForm?.colonia || "",
    calle: domicilioFiscalForm?.calle || "",
    numeroExterior: domicilioFiscalForm?.numeroExterior || "",
    numeroInterior: domicilioFiscalForm?.numeroInterior || "",
  });
  }, [domicilioFiscalFormRef, currentTrabajadorCopy, domicilioFiscalForm]);

  const [tabOpenBeneficiarios, setTabOpenBeneficiarios] = useState(false);
  const [nombreDelFamiliar, setNombreDelFamiliar] = useState("");
  const [tiposDeParentesco, setTiposDeParentesco] = useState("Cónyuge");
  const [telefonoBeneficiario, setTelefonoBeneficiario] = useState("");
  const [fechaDeNacimientoBeneficiario, setFechaDeNacimientoBeneficiario] = useState<any>(null);
  const [selectedItemBeneficiario, setSelectedItemBeneficiario] = useState<any>(null);
  const [beneficiariosArray, setBeneficiariosArray] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.beneficiarios : []);
  const beneficiariosColumnsArray = [
    { label: 'Nombre', valueLabel: 'nombreDelFamiliar', filtrable: true, widthPX: 250, type: 'String' },
    { label: 'Parentesco', valueLabel: 'parentesco', filtrable: true, widthPX: 200, type: 'String' },
    { label: 'Fecha de Nacimiento', valueLabel: 'fechaDeNacimiento', filtrable: true, widthPX: 200, type: 'Date' },
    { label: 'Telefono', valueLabel: 'telefono', filtrable: true, widthPX: 200, type: 'String' },
  ];

  const [tabOpenContrato, setTabOpenContrato] = useState(false);
  const [departamento, setDepartamento] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.departamento : "");
  const [fechaDeIngreso, setFechaDeIngreso] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.fechaDeIngreso : null);
  const [puestoActual, setPuestoActual] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.puestoActual : "");
  const [fechaDeReconocimientoDeAntiguedad, setFechaDeReconocimientoDeAntiguedad] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.fechaDeReconocimientoDeAntiguedad : null);
  const [tipoDeContrato, setTipoDeContrato] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.tipoDeContrato : "Por tiempo indeterminado");
  const [puestoDeConfianza, setPuestoDeConfianza] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.puestoDeConfianza : false);
  const [fechaDeExpiracion, setFechaDeExpiracion] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.fechaDeExpiracion : null);
  const [monto, setMonto] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.monto : "");
  const [causaQueDaOrigen, setCausaQueDaOrigen] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.contrato.causaQueDaOrigen : "");

  const tipoDeContratoArray = [
    'Por tiempo indeterminado', //esta es el unico que no tiene fecha de expiracion 
    'Por tiempo indeterminado sujeto a un período de prueba',
    'Por tiempo indeterminado sujeto a un período de capacitación inicial',
    'Por tiempo determinado', //qui se usa causa obligatorio
    'Por obra determinada', //qui se usa causa obligatorio
    'Para la inversión de capital determinado', //este tiene monto (puede ser texto) //qui se usa causa obligatorio
  ];

  const [currentHorrarioIndex, setCurrentHorrarioIndex] = useState((currentTrabajadorCopy && currentTrabajadorCopy.horario.length > 0) ? currentTrabajadorCopy.horario.length - 1 : 0);
  const [tabOpenHorario, setTabOpenHorario] = useState(false);
  const [usarSecuenciaDeHorarios, setUsarSecuenciaDeHorarios] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].usarSecuenciaDeHorarios : false);
  const [horario, setHorario] = useState(currentTrabajadorCopy && empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.id === currentTrabajadorCopy.horario[currentHorrarioIndex].horario && !H.inactivo)[0] ? empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.id === currentTrabajadorCopy.horario[currentHorrarioIndex].horario && !H.inactivo)[0].clave : "Sin horario");
  const [secuencia, setSecuencia] = useState(currentTrabajadorCopy && empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => S.id === currentTrabajadorCopy.horario[currentHorrarioIndex].secuencia && !S.inactivo)[0] ? empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => S.id === currentTrabajadorCopy.horario[currentHorrarioIndex].secuencia && !S.inactivo)[0].clave : "Sin secuencia");
  const [considerarTiempoLaboradoEnHorarioDeComida, setConsiderarTiempoLaboradoEnHorarioDeComida] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].considerarTiempoLaboradoEnHorarioDeComida : false);
  const [considerarEntradasAnticipadasComoTiempoExtra, setConsiderarEntradasAnticipadasComoTiempoExtra] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].considerarEntradasAnticipadasComoTiempoExtra : false);
  const [pagarHorasExtra, setPagarHorasExtra] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].pagarHorasExtra : false);
  const [secuenciaDeEntradasYSalidasH, setSecuenciaDeEntradasYSalidasH] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].secuenciaDeEntradasYSalidasH : null);
  const [secuenciaDeEntradasYSalidasS, setSecuenciaDeEntradasYSalidasS] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].secuenciaDeEntradasYSalidasS : null);
  const [usarHorarioEspecial, setUsarHorarioEspecial] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].usarHorarioEspecial : false);
  const [arregloDeCiclosEspecial, setArregloDeCiclosEspecial] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].arregloDeCiclosEspecial : []);
  const [tiempoXTiempo, setTiempoXTiempo] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].tiempoXTiempo : "");
  const [fechaDeInicioEspecial, setFechaDeInicioEspecial] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].fechaDeInicioEspecial : null);
  const [horaDeInicio, setHoraDeInicio] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].horaDeInicio : "");
  const [cicloDeInicioEspecial, setCicloDeInicioEspecial] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario[currentHorrarioIndex].cicloDeInicioEspecial : "");
  const [bancoDespensaArray, setBancoDespensaArray] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.bancoDespensaArray : []);
  const [formaDePago, setFormaDePago] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.formaDePago : "Efectivo");
  const [formaDePagoCuenta, setFormaDePagoCuenta] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.formaDePagoCuenta : "");
  const [bancoDespensa, setBancoDespensa] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.bancoDespensa : "");
  const [tarjetaDespensa, setTarjetaDespensa] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.tarjetaDespensa : "");
  const [esquemaDeNominaArray, setEsquemaDeNominaArray] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.esquemaDeNominaArray : []);
  const [esquemaDeNomina, setEsquemaDeNomina] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.esquemaDeNomina : "Semanal");
  const [siguenteDiaAPagar, setSiguenteDiaAPagar] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.siguenteDiaAPagar : null);
  const [siguenteDiaAPagarActive, setSiguenteDiaAPagarActive] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.siguenteDiaAPagarActive : false);
  const [zona, setZona] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.zona : "General");
  const [diasPendientesDeDisfrutar, setDiasPendientesDeDisfrutar] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.diasPendientesDeDisfrutar : 0);
  const [creditoInfonavit, setCreditoInfonavit] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.creditoInfonavit : "");
  const [fechaInfonavit, setFechaInfonavit] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.fechaInfonavit : null);
  const [descuento, setDescuento] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.descuento : "Ninguno");
  const [descuentoPorcentaje, setDescuentoPorcentaje] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.descuentoPorcentaje : "");
  const [descuentoCuotaFija, setDescuentoCuotaFija] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.descuentoCuotaFija : "");
  const [descuentoVSM, setDescuentoVSM] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.descuentoVSM : "");
  const [omitirSubsidio, setOmitirSubsidio] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.omitirSubsidio : false);
  const [activeTabNomina, setActiveTabNomina] = useState("ACTUAL");
  const [cuotaDiaria, setCuotaDiaria] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.cuotaDiaria : "");
  const [salarioBase, setSalarioBase] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.salarioBase : "");
  const [salarioBaseFecha, setSalarioBaseFecha] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.salarioBaseFecha : null);
  const [activeButtonNomina, setActiveButtonNomina] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.activeButtonNomina : false);
  const [historialCompletoArray, setHistorialCompletoArray] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.historialCompletoArray : []);
  const [variablesIMSSArray, setVariablesIMSSArray] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.variablesIMSSArray : []);
  const [arregloDesgloceSalario, setArregloDesgloceSalario] = useState<any>(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.arregloDesgloceSalario : []);
  const [modalSalarioDetalles, setModalSalarioDetalles] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.nomina.modalSalarioDetalles : false);
  const [justAdded, setJustAdded] = useState(false);
  const [ultimaFecha, setUltimaFecha] = useState((currentTrabajadorCopy  && currentTrabajadorCopy.horario.length > 1) ? currentTrabajadorCopy.horario[currentTrabajadorCopy.horario.length - 2].fechaDeFinalizacion : new Date());
  const [periodo, setPeriodo] = useState(currentTrabajadorCopy ? currentTrabajadorCopy.horario.periodo : null);
  const [eliminarHorarioValid, setEliminarHorarioValid] = useState(false);
  const [modalCambioDeHorarioOpen, setModalCambioDeHorarioOpen] = useState(false);
  const sumOneDay = (date: any) => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  }
  const [selectedDayModalCambioDeHorario, setSelectedDayModalCambioDeHorario] = useState<any>(sumOneDay(ultimaFecha));
  const [disableHorarios, setDisableHorarios] = useState(currentTrabajador);


  const historialCompletoColumnsArray = [
    { label: 'Fecha de edición', valueLabel: 'fechaDeEdicion', filtrable: true, widthPX: 195, type: 'Date' },
    { label: 'Fecha', valueLabel: 'fecha', filtrable: false, widthPX: 200, type: 'Date' },
    { label: 'Cuota Diaria', valueLabel: 'cuotaDiaria', filtrable: false, widthPX: 215, type: 'String' },
    { label: 'Salario Base', valueLabel: 'salarioBase', filtrable: false, widthPX: 215, type: 'String' },
  ];

  const variablesIMSSColumnsArray = [
    { label: 'Año', valueLabel: 'año', filtrable: false, widthPX: 195, type: 'String' },
    { label: 'Bimestre', valueLabel: 'bimestre', filtrable: false, widthPX: 215, type: 'String' },
    { label: 'Acumulado', valueLabel: 'acumulado', filtrable: false, widthPX: 215, type: 'String' },
    { label: 'Días Cotizados', valueLabel: 'diasCotizados', filtrable: false, widthPX: 215, type: 'String' },
    { label: 'Valor Diario', valueLabel: 'valorDiario', filtrable: false, widthPX: 215, type: 'String' },
  ]

  useEffect(() => {
    setEliminarHorarioValid(
      (currentTrabajadorCopy?.horario?.length - 1 === currentHorrarioIndex) &&
      (currentTrabajadorCopy.horario[currentTrabajadorCopy.horario.length - 2]?.fechaDeFinalizacion  && 
    (new Date(currentTrabajadorCopy.horario[currentTrabajadorCopy.horario.length - 2].fechaDeFinalizacion).getTime() >= new Date(new Date().getTime()).setHours(0, 0, 0, 0))))
  }, [currentTrabajadorCopy]);

  useEffect(() => {
    let currentTrabajadorHorario = null;
    if(currentTrabajadorCopy){
       currentTrabajadorHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.id === currentTrabajadorCopy.horario[currentHorrarioIndex].horario && !H.inactivo)[0] ? empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.id === currentTrabajadorCopy.horario[currentHorrarioIndex].horario && !H.inactivo)[0].clave : "Sin horario";
      if(horario !== currentTrabajadorHorario) {
        setUsarHorarioEspecial(false);
        setCicloDeInicioEspecial('');
        setFechaDeInicioEspecial(null);
      }
    }
    let currentHorario = [];
    let currentHorarioObject = {};
    if (horario && horario !== 'Sin horario') {
      currentHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.clave === horario);
      const usarHorarioEspecial =  currentHorario[currentHorario.length - 1]?.['horarioEspecial']?.['horariosEspecialesArray'];
      setUsarHorarioEspecial(usarHorarioEspecial);
      if (usarHorarioEspecial) {
        setArregloDeCiclosEspecial(currentHorario[currentHorario.length - 1]['horarioEspecial']['horariosEspecialesArray'].map((horarioVar: any, index: number) => 'Periodo: ' + (index + 1) + ' Trabajadas: ' + horarioVar.trabajadas + ' Descanso: ' + horarioVar.descanso));
      }
      if(currentTrabajadorHorario && horario !== currentTrabajadorHorario) {
      currentHorarioObject = {
        lunes: currentHorario[currentHorario.length - 1].lunes.horarios ? currentHorario[currentHorario.length - 1].lunes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        martes: currentHorario[currentHorario.length - 1].martes.horarios ? currentHorario[currentHorario.length - 1].martes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        miercoles: currentHorario[currentHorario.length - 1].miercoles.horarios ? currentHorario[currentHorario.length - 1].miercoles.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        jueves: currentHorario[currentHorario.length - 1].jueves.horarios ? currentHorario[currentHorario.length - 1].jueves.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        viernes: currentHorario[currentHorario.length - 1].viernes.horarios ? currentHorario[currentHorario.length - 1].viernes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        sabado: currentHorario[currentHorario.length - 1].sabado.horarios ? currentHorario[currentHorario.length - 1].sabado.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        domingo: currentHorario[currentHorario.length - 1].domingo.horarios ? currentHorario[currentHorario.length - 1].domingo.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
      }
      setSecuenciaDeEntradasYSalidasH(currentHorarioObject);
    }
    }
  }, [horario]);

  useEffect(() => {
    setBancoDespensaArray([...empresa.empresa.esquemaDeNomina.cuentasBancariasParaPagoDeNomina.cuentasBancarias.map((banco: any) => banco.nombreDelBanco + ' - ' + banco.numeroDeCuenta)])
  }, [empresa.empresa.esquemaDeNomina.cuentasBancariasParaPagoDeNomina.cuentasBancarias]);

  useEffect(() => {
    if (fechaDeReconocimientoDeAntiguedad) {
      let dias: any = {};
      if (esquemaDeNomina === 'Semanal') {
        dias = getDifferenceInDays(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialSemanal, fechaDeReconocimientoDeAntiguedad);
        setSiguenteDiaAPagar(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialSemanal);
      }
      if (esquemaDeNomina === 'Decenal') {
        dias = getDifferenceInDays(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialDecenal, fechaDeReconocimientoDeAntiguedad);
        setSiguenteDiaAPagar(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialDecenal);
      }
      if (esquemaDeNomina === 'Catorcenal') {
        dias = getDifferenceInDays(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialCatorcenal, fechaDeReconocimientoDeAntiguedad);
        setSiguenteDiaAPagar(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialCatorcenal);
      }
      if (esquemaDeNomina === 'Quincenal') {
        dias = getDifferenceInDays(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialQuincenal, fechaDeReconocimientoDeAntiguedad);
        setSiguenteDiaAPagar(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialQuincenal);
      }
      if (dias['days'] && !(dias['days'] < 0)) {
        setSiguenteDiaAPagar(fechaDeReconocimientoDeAntiguedad);
      }
    }

  }, [esquemaDeNomina, fechaDeReconocimientoDeAntiguedad]);

  useEffect(() => {
    if (fechaDeReconocimientoDeAntiguedad) {
      let dias: any = {};
      if (esquemaDeNomina === 'Semanal') {
        dias = getDifferenceInDays(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialSemanal, fechaDeReconocimientoDeAntiguedad);
      }
      if (esquemaDeNomina === 'Decenal') {
        dias = getDifferenceInDays(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialDecenal, fechaDeReconocimientoDeAntiguedad);
      }
      if (esquemaDeNomina === 'Catorcenal') {
        dias = getDifferenceInDays(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialCatorcenal, fechaDeReconocimientoDeAntiguedad);
      }
      if (esquemaDeNomina === 'Quincenal') {
        dias = getDifferenceInDays(empresa.empresa.esquemaDeNomina.configuracion.periodoInicialQuincenal, fechaDeReconocimientoDeAntiguedad);
      }
      if (dias['days']) {
        setSiguenteDiaAPagarActive(dias['days'] < 0);
      }
    }

  }, [fechaDeReconocimientoDeAntiguedad]);

  useEffect(() => {
    const esquemaDeNominaArrayCopy = [];
    if (empresa.empresa.esquemaDeNomina.configuracion.semanalCheckBox) { esquemaDeNominaArrayCopy.push('Semanal') }
    if (empresa.empresa.esquemaDeNomina.configuracion.decenalCheckBox) { esquemaDeNominaArrayCopy.push('Decenal') }
    if (empresa.empresa.esquemaDeNomina.configuracion.catorcenalCheckBox) { esquemaDeNominaArrayCopy.push('Catorcenal') }
    if (empresa.empresa.esquemaDeNomina.configuracion.quincenalCheckBox) { esquemaDeNominaArrayCopy.push('Quincenal') }
    setEsquemaDeNominaArray(esquemaDeNominaArrayCopy)
    setEsquemaDeNomina(esquemaDeNominaArrayCopy[0])
  }, [empresa.empresa.esquemaDeNomina.esquemasDeNomina]);

  useEffect(() => {
    let currentSecuencia: any[] = [];
    let horName = '';
    let currentSecuenciaObject = [];
    if(currentTrabajadorCopy && currentHorrarioIndex !== currentTrabajadorCopy.horario.length - 1){
      return 
   }
    if (usarSecuenciaDeHorarios && secuencia !== 'Sin secuencia') {
      currentSecuencia = empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => {
        return S.clave === secuencia
      });
      currentSecuencia = currentSecuencia[currentSecuencia.length - 1]['secuencia'].map((sec: any) => {
        return sec.clave
      })
      // let currentSecuenciaSet = new Set(currentSecuencia);
      const currentSecuenciaSet = currentSecuencia.filter((item: any, index: number) => currentSecuencia.indexOf(item) === index);
      for (const horario of currentSecuenciaSet) {
        let currentHorario = [];
        let currentHorarioObject = {};
        currentHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => {
          return H.clave === horario
        });
        horName = currentHorario[currentHorario.length - 1]['clave']
        currentHorarioObject = {
          lunes: currentHorario[currentHorario.length - 1].lunes.horarios ? currentHorario[currentHorario.length - 1].lunes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          martes: currentHorario[currentHorario.length - 1].martes.horarios ? currentHorario[currentHorario.length - 1].martes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          miercoles: currentHorario[currentHorario.length - 1].miercoles.horarios ? currentHorario[currentHorario.length - 1].miercoles.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          jueves: currentHorario[currentHorario.length - 1].jueves.horarios ? currentHorario[currentHorario.length - 1].jueves.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          viernes: currentHorario[currentHorario.length - 1].viernes.horarios ? currentHorario[currentHorario.length - 1].viernes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          sabado: currentHorario[currentHorario.length - 1].sabado.horarios ? currentHorario[currentHorario.length - 1].sabado.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          domingo: currentHorario[currentHorario.length - 1].domingo.horarios ? currentHorario[currentHorario.length - 1].domingo.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        }
        currentSecuenciaObject.push({ ...currentHorarioObject, horario: horName })
      }
      setSecuenciaDeEntradasYSalidasS(currentSecuenciaObject);
    }
  }, [secuencia]);



  const [tabOpenNomina, setTabOpenNomina] = useState(false);

  useEffect(() => {
    setFechaDeReconocimientoDeAntiguedad(fechaDeIngreso);
  }, [fechaDeIngreso]);


  const submitForm = () => {
    if (!numeroDeNomina) {
      messageSubject.next('Todos los trabajadores requieren un numero de nomina');
      scrollIntoView(alertRef);
      return false
    }
    if(currentTrabajadorCopy && Object.keys(currentTrabajadorCopy).length !== 0){
      if (validateForm()) {
        editarTrabajador();
      }
    } else {
      if (step === 1) {
        if (!isDatosPersonalesValid()) {
          messageSubject.next('Parece que la seccion datos personales esta incompleta');
          scrollIntoView(alertRef);
          return false
        } else { setStep(2); messageSubject.next(''); }
      }
      if (step === 2) { setStep(3); }
      if (step === 3) {
        if (!isContratoValid()) {
          messageSubject.next('Parece que la seccion contrato esta incompleta');
          scrollIntoView(alertRef);
          return false
        } else { setStep(4); messageSubject.next(''); }
      }
      if (step === 4) {
        if (!isHorarioValid()) {
          messageSubject.next('Parece que la seccion horario esta incompleta');
          scrollIntoView(alertRef);
          return false
        } else { 
          setStep(5); messageSubject.next('');
         }
      }
      if (step === 5) {
        if (validateForm()) {
          crearTrabajador();
        }
      }
    }
  };
  const cancel = () => {
    dispatch(
      actions.currentTrabajador.resetCurrentTrabajador()
    );
    resetAll();
    history(paths.ControlDePersonal);
  };

  const alreadyImported = (alreadyImportedTrabajador : any) => {
    setCurrentTrabajadorCopy(deepCopy(alreadyImportedTrabajador));
    setNumeroDeNomina(alreadyImportedTrabajador.info.numeroDeNomina);
    setApellidoPaterno(alreadyImportedTrabajador.datosPersonales.datosPersonales.apellidoPaterno);
    setApellidoMaterno(alreadyImportedTrabajador.datosPersonales.datosPersonales.apellidoMaterno);
    setNombre(alreadyImportedTrabajador.datosPersonales.datosPersonales.nombre);
    setUserImage(alreadyImportedTrabajador.datosPersonales.datosPersonales.userImage);
    setCorreoElectronico(alreadyImportedTrabajador.datosPersonales.datosPersonales.correoElectronico);
    setTelefonoMovil(alreadyImportedTrabajador.datosPersonales.datosPersonales.telefonoMovil);
    setFechaDeNacimiento(alreadyImportedTrabajador.datosPersonales.datosPersonales.fechaDeNacimiento);
    setSexo(alreadyImportedTrabajador.datosPersonales.datosPersonales.sexo);
    setLugarDeNacimiento(alreadyImportedTrabajador.datosPersonales.datosPersonales.lugarDeNacimiento);
    setEntidadFederativa(alreadyImportedTrabajador.datosPersonales.datosPersonales.entidadFederativa);
    setNacionalidad(alreadyImportedTrabajador.datosPersonales.datosPersonales.nacionalidad);
    setTipoDePension(alreadyImportedTrabajador.datosPersonales.datosPersonales.tipoDePension);
    setEscolaridad(alreadyImportedTrabajador.datosPersonales.datosPersonales.escolaridad);
    setEstadoCivil(alreadyImportedTrabajador.datosPersonales.datosPersonales.estadoCivil);
    setNumeroDelIMSS(alreadyImportedTrabajador.datosPersonales.datosPersonales.numeroDelIMSS);
    setPermitirLlenado(alreadyImportedTrabajador.datosPersonales.datosPersonales.permitirLlenado);
    setTipoDeLlenado(alreadyImportedTrabajador.datosPersonales.datosPersonales.tipoDeLlenado);

    setBeneficiariosArray(alreadyImportedTrabajador.beneficiarios.beneficiarios || []);

    setDepartamento(alreadyImportedTrabajador.contrato.departamento);
    setFechaDeIngreso(alreadyImportedTrabajador.contrato.fechaDeIngreso);
    setPuestoActual(alreadyImportedTrabajador.contrato.puestoActual);
    setFechaDeReconocimientoDeAntiguedad(alreadyImportedTrabajador.contrato.fechaDeReconocimientoDeAntiguedad);
    setTipoDeContrato(alreadyImportedTrabajador.contrato.tipoDeContrato);
    setPuestoDeConfianza(alreadyImportedTrabajador.contrato.puestoDeConfianza);
    setFechaDeExpiracion(alreadyImportedTrabajador.contrato.fechaDeExpiracion);
    setMonto(alreadyImportedTrabajador.contrato.monto);
    setCausaQueDaOrigen(alreadyImportedTrabajador.contrato.causaQueDaOrigen);

    setUsarSecuenciaDeHorarios(alreadyImportedTrabajador.horario.usarSecuenciaDeHorarios);
    setHorario(empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.id === alreadyImportedTrabajador.horario[currentHorrarioIndex].horario && !H.inactivo)[0] ? empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.id === alreadyImportedTrabajador.horario[currentHorrarioIndex].horario && !H.inactivo)[0].clave: "Sin horario");
    setSecuencia(empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => S.id === alreadyImportedTrabajador.horario[currentHorrarioIndex].secuencia && !S.inactivo)[0] ? empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => S.id === alreadyImportedTrabajador.horario[currentHorrarioIndex].secuencia && !S.inactivo)[0].clave: "Sin secuencia");
    setConsiderarTiempoLaboradoEnHorarioDeComida(alreadyImportedTrabajador.horario[currentHorrarioIndex].considerarTiempoLaboradoEnHorarioDeComida);
    setConsiderarEntradasAnticipadasComoTiempoExtra(alreadyImportedTrabajador.horario[currentHorrarioIndex].considerarEntradasAnticipadasComoTiempoExtra);
    setPagarHorasExtra(alreadyImportedTrabajador.horario[currentHorrarioIndex].pagarHorasExtra);
    setSecuenciaDeEntradasYSalidasH(alreadyImportedTrabajador.horario[currentHorrarioIndex].secuenciaDeEntradasYSalidasH);
    setSecuenciaDeEntradasYSalidasS(alreadyImportedTrabajador.horario[currentHorrarioIndex].secuenciaDeEntradasYSalidasS);
    setUsarHorarioEspecial(alreadyImportedTrabajador.horario[currentHorrarioIndex].usarHorarioEspecial);
    setArregloDeCiclosEspecial(alreadyImportedTrabajador.horario[currentHorrarioIndex].arregloDeCiclosEspecial);
    setTiempoXTiempo(alreadyImportedTrabajador.horario[currentHorrarioIndex].tiempoXTiempo);
    setFechaDeInicioEspecial(alreadyImportedTrabajador.horario[currentHorrarioIndex].fechaDeInicioEspecial);
    setHoraDeInicio(alreadyImportedTrabajador.horario[currentHorrarioIndex].horaDeInicio);
    setCicloDeInicioEspecial(alreadyImportedTrabajador.horario[currentHorrarioIndex].cicloDeInicioEspecial);
    setBancoDespensaArray([...empresa.empresa.esquemaDeNomina.cuentasBancariasParaPagoDeNomina.cuentasBancarias.map((banco: any) => banco.nombreDelBanco + ' - ' + banco.numeroDeCuenta)])
    setFormaDePago(alreadyImportedTrabajador.nomina.formaDePago);
    setFormaDePagoCuenta(alreadyImportedTrabajador.nomina.formaDePagoCuenta);
    setBancoDespensa(alreadyImportedTrabajador.nomina.bancoDespensa);
    setTarjetaDespensa(alreadyImportedTrabajador.nomina.tarjetaDespensa);
    setEsquemaDeNomina(alreadyImportedTrabajador.nomina.esquemaDeNomina);
    setSiguenteDiaAPagar(alreadyImportedTrabajador.nomina.siguenteDiaAPagar);
    setSiguenteDiaAPagarActive(alreadyImportedTrabajador.nomina.siguenteDiaAPagarActive);
    setZona(alreadyImportedTrabajador.nomina.zona);
    setDiasPendientesDeDisfrutar(alreadyImportedTrabajador.nomina.diasPendientesDeDisfrutar);
    setCreditoInfonavit(alreadyImportedTrabajador.nomina.creditoInfonavit);
    setFechaInfonavit(alreadyImportedTrabajador.nomina.fechaInfonavit);
    setDescuento(alreadyImportedTrabajador.nomina.descuento);
    setDescuentoPorcentaje(alreadyImportedTrabajador.nomina.descuentoPorcentaje);
    setDescuentoCuotaFija(alreadyImportedTrabajador.nomina.descuentoCuotaFija);
    setDescuentoVSM(alreadyImportedTrabajador.nomina.descuentoVSM);
    setOmitirSubsidio(alreadyImportedTrabajador.nomina.omitirSubsidio);
    setCuotaDiaria(alreadyImportedTrabajador.nomina.cuotaDiaria);
    setSalarioBase(alreadyImportedTrabajador.nomina.salarioBase);
    setSalarioBaseFecha(alreadyImportedTrabajador.nomina.salarioBaseFecha);
    setActiveButtonNomina(alreadyImportedTrabajador.nomina.activeButtonNomina);
    setHistorialCompletoArray(alreadyImportedTrabajador.nomina.historialCompletoArray);
    setVariablesIMSSArray(alreadyImportedTrabajador.nomina.variablesIMSSArray);
    setArregloDesgloceSalario(alreadyImportedTrabajador.nomina.arregloDesgloceSalario);
    setModalSalarioDetalles(alreadyImportedTrabajador.nomina.modalSalarioDetalles);
    setUltimaFecha((alreadyImportedTrabajador  && alreadyImportedTrabajador.horario.length > 1) ? alreadyImportedTrabajador.horario[alreadyImportedTrabajador.horario.length - 2].fechaDeFinalizacion : new Date())
    setPeriodo(alreadyImportedTrabajador.horario.periodo);
    setDisableHorarios(false);
  }

  const resetAll = () => {
    setCurrentTrabajadorCopy(null);
    setStep(1);
    setNumeroDeNomina(currentTrabajadorCopy ? currentTrabajadorCopy.info.numeroDeNomina : "");
    setTabOpenDatosersonales(false);
    messageSubject.next('');
    warningSubject.next('');
    successfulSubject.next('');
    warningfulSubjectLLenado.next('');

    setCURP("");
    setCURPIsInvalid(false);
    setCURPIsTouched(false);
    setCURPlengthIsInvalid(false);
    setRFC("");
    setRFCIsInvalid(false);
    setRFCIsTouched(false);
    setRFClengthIsInvalid(false);
    setApellidoPaterno("");
    setApellidoPaternoValid(true);
    setApellidoMaterno("");
    setApellidoMaternoValid(true);
    setNombre("");
    setNombreValid(true);
    setUserImage(null);
    setCorreoElectronico("");
    setIsCorreoElectronicoValid(true);
    setTelefonoMovil("");
    setFechaDeNacimiento("");
    setSexo("");
    setLugarDeNacimiento("");
    setEntidadFederativa("");
    setNacionalidad("");
    setTipoDePension("Sin pensión");
    setEscolaridad("Ninguna");
    setEstadoCivil("Soltero(a)");
    setNumeroDelIMSS("");
    setPermitirLlenado(false);
    setTipoDeLlenado("Marcado de entradas y salidas");
    domicilioFiscalFormRef.current?.cleanSubjects();
    domicilioFiscalFormRef.current?.patchValuesFunction({
      codigoPostal: "",
      municipio: "",
      estado: "",
      colonia: "",
      calle: "",
      numeroExterior: "",
      numeroInterior: "",
    });

    setTabOpenBeneficiarios(false);
    setNombreDelFamiliar("");
    setTiposDeParentesco("Cónyuge");
    setTelefonoBeneficiario("");
    setFechaDeNacimientoBeneficiario(null);
    setSelectedItemBeneficiario(null);
    setBeneficiariosArray(currentTrabajadorCopy ? currentTrabajadorCopy.beneficiarios : []);
  
    setTabOpenContrato(false);
    setDepartamento("");
    setFechaDeIngreso(null);
    setPuestoActual("");
    setFechaDeReconocimientoDeAntiguedad(null);
    setTipoDeContrato("Por tiempo indeterminado");
    setPuestoDeConfianza(false);
    setFechaDeExpiracion(null);
    setMonto("");
    setCausaQueDaOrigen("");

    setCurrentHorrarioIndex(0);
    setTabOpenHorario(false);
    setUsarSecuenciaDeHorarios(false);
    setHorario("Sin horario");
    setSecuencia("Sin secuencia");
    setConsiderarTiempoLaboradoEnHorarioDeComida(false);
    setConsiderarEntradasAnticipadasComoTiempoExtra(false);
    setPagarHorasExtra(false);
    setSecuenciaDeEntradasYSalidasH(null);
    setSecuenciaDeEntradasYSalidasS(null);
    setUsarHorarioEspecial(false);
    setArregloDeCiclosEspecial([]);
    setTiempoXTiempo("");
    setFechaDeInicioEspecial(null);
    setHoraDeInicio("");
    setCicloDeInicioEspecial("");
    setBancoDespensaArray([]);
    setFormaDePago("Efectivo");
    setFormaDePagoCuenta("");
    setBancoDespensa("");
    setTarjetaDespensa("");
    setEsquemaDeNominaArray([]);
    setEsquemaDeNomina("Semanal");
    setSiguenteDiaAPagar(null);
    setSiguenteDiaAPagarActive(false);
    setZona("General");
    setDiasPendientesDeDisfrutar(0);
    setCreditoInfonavit("");
    setFechaInfonavit(null);
    setDescuento("Ninguno");
    setDescuentoPorcentaje("");
    setDescuentoCuotaFija("");
    setDescuentoVSM("");
    setOmitirSubsidio(false);
    setCuotaDiaria("");
    setSalarioBase("");
    setSalarioBaseFecha(null);
    setActiveButtonNomina(false);
    setHistorialCompletoArray([]);
    setVariablesIMSSArray([]);
    setArregloDesgloceSalario([]);
    setModalSalarioDetalles(false);
    setJustAdded(false);
    setUltimaFecha(new Date());
    setPeriodo(null);
    setEliminarHorarioValid(false);
    setModalCambioDeHorarioOpen(false);
    setSelectedDayModalCambioDeHorario(sumOneDay(ultimaFecha));
    setDisableHorarios(false);

  
  }
  

  const onNoNominaBlur = () => {
    if (numeroDeNomina === "") {
      messageSubject.next("El campo No de Nomina es obligatorio");
      scrollIntoView(alertRef);
    }
    if (empresa.trabajadores.controlDePersonal.trabajadores.find((trabajador: any) => trabajador.info.numeroDeNomina === numeroDeNomina && numeroDeNomina !== currentTrabajadorCopy?.info.numeroDeNomina)) {
      messageSubject.next("Ya existe un trabajador con ese No de Nomina");
      scrollIntoView(alertRef);
    }
    else messageSubject.next("");
  }

  const onSelectFile = (event: { target: { files: any[] } }) => {
    if (event.target.files && event.target.files[0]) {
      const image = event.target.files[0];

      // Resize the image using react-image-file-resizer
      Resizer.imageFileResizer(
        image,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri: any) => {
          setUserImage(uri);
        },
        "base64"
      );
    }
  };

  const removeIMG = () => {
    setUserImage(null);
  };

  const regex = /[^\w-@.\s]/g;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const omit_special_char_email = (string: any) => {
    let newString = string.replace(regex, "");
    setCorreoElectronico(newString);
    setIsCorreoElectronicoValid(!newString || emailRegex.test(newString.toLowerCase()));
  };

  const CURPFocusOut = () => {
    setCURPIsTouched(true);
    setRFCIsTouched(true);
    const CURPValue = curp.replace(/[^A-Z0-9]+/ig, "").toUpperCase();
    const CURPFirst10 = CURPValue.substring(0, 10);
    const RFCrest = rfc.substring(10).toUpperCase();
    setRFC(CURPFirst10 + RFCrest);
    setCURP(CURPValue);
    messageSubject.next('');
    setFechaDeNacimiento('');
    setSexo('');
    setLugarDeNacimiento('');
    setEntidadFederativa('');
    setNacionalidad('');
    const CURPValueChars = CURPValue.split('');
    if (CURPValue.length === 18) {
      if (
        isNaN(+CURPValueChars[4]) ||
        isNaN(+CURPValueChars[5]) ||
        isNaN(+CURPValueChars[6]) ||
        isNaN(+CURPValueChars[7]) ||
        isNaN(+CURPValueChars[8]) ||
        isNaN(+CURPValueChars[9])
      ) {
        messageSubject.next('Los caracteres del 5 al 10 en el CURP y R.F.C. representan la fecha de nacimiento y deben ser todos numeros');
      } else {
        const NewFechaDeNacimiento = CURPValueChars[8] + CURPValueChars[9] + "/" + CURPValueChars[6] + CURPValueChars[7] + "/" + obtenerAñoDeNacimiento(CURPValue);
        setFechaDeNacimiento(NewFechaDeNacimiento);
      }
      if (CURPValueChars[10] === 'H' || CURPValueChars[10] === 'M') {
        setSexo(CURPValueChars[10] === 'H' ? 'Masculino' : 'Femenino');
      } else {
        messageSubject.next('El caracter numero 11 representa el sexo y debe ser M o H');
      }
      const newEntidadFederativa = CURPValueChars[11] + CURPValueChars[12] + '';
      const newLugarDeNacimiento = entidadesFederativasLiteral.hasOwnProperty(newEntidadFederativa) ? (entidadesFederativasLiteral as any)[newEntidadFederativa]() : undefined;
      if (!newLugarDeNacimiento) {
        messageSubject.next('Los caracteres 12 y 13 que representan la entidad federativa no son validos');
      } else {
        if (newEntidadFederativa !== 'NE') { setNacionalidad('Mexicana'); }
        setLugarDeNacimiento(newLugarDeNacimiento);
        setEntidadFederativa(newEntidadFederativa);
      }
    }
  }

  useEffect(() => {
    if (curpIsTouched) {
      setCURPlengthIsInvalid(curp.length !== 18);
    }
    setCURPIsInvalid(curp.length === 0 && curpIsTouched);
  }, [curp, curpIsTouched]);

  useEffect(() => {
    if (rfcIsTouched) {
      setRFClengthIsInvalid(rfc.length !== 13);
    }
    setRFCIsInvalid(rfc.length === 0 && rfcIsTouched);
  }, [rfc, rfcIsTouched]);

  useEffect(() => {
    permitirLlenado ? warningfulSubjectLLenado.next('Al permitir el llenado por el trabajador, se autogenerara una cuenta y se le enviara un correo al correo señalado en el cual se adjuntara su contraseña, utilizando esta y el correo señalado, el trabajador podra iniciar secion en la version de SIP para trabajadores, dentro de la cual podra llenar sus horas de trabajo segun el metodo señalado.') : warningfulSubjectLLenado.next('');
  }, [permitirLlenado]);

  const isDatosPersonalesValid = () => {
    return !curpLengthIsInvalid && !curpIsInvalid && nombreValid && !rfcIsInvalid && apellidoPaternoValid && apellidoMaternoValid
      && isCorreoElectronicoValid && !rfcLengthIsInvalid && rfc && curp && nombre && apellidoPaterno && apellidoMaterno && nacionalidad && lugarDeNacimiento && fechaDeNacimiento && sexo && estadoCivil;
  }

  const isContratoValid = () => {
    return (tipoDeContrato === 'Por tiempo indeterminado' ||
      (tipoDeContrato === 'Por tiempo indeterminado sujeto a un período de prueba' && fechaDeExpiracion !== null) ||
      (tipoDeContrato === 'Por tiempo indeterminado sujeto a un período de capacitación inicial' && fechaDeExpiracion !== null) ||
      (tipoDeContrato === 'Por tiempo determinado' && fechaDeExpiracion !== null && causaQueDaOrigen !== '') ||
      (tipoDeContrato === 'Por obra determinada' && fechaDeExpiracion !== null && causaQueDaOrigen !== '') ||
      (tipoDeContrato === 'Para la inversión de capital determinado' && monto !== '' && causaQueDaOrigen !== '')
    ) && fechaDeIngreso && puestoActual && departamento && fechaDeReconocimientoDeAntiguedad;
  }

  const isHorarioValid = () => {
    const isHorarioValid = usarSecuenciaDeHorarios ? secuencia !== 'Sin secuencia' : usarHorarioEspecial ? (cicloDeInicioEspecial && fechaDeInicioEspecial && horaDeInicio) : horario !== 'Sin horario';
    if (isHorarioValid && currentTrabajadorCopy && Object.keys(currentTrabajadorCopy).length !== 0) {
      setCurrentHorrarioIndex(currentTrabajadorCopy.horario.length - 1);
      setDisableHorarios(currentHorrarioIndex !== currentTrabajadorCopy.horario.length - 1 || !justAdded);
      // replaceData();
    }
    return isHorarioValid;
  }

  const isNominaValid = () => {
    const isNominaValid = (descuento === "Ninguno" || (descuento === "Porcentaje" && descuentoPorcentaje) || (descuento === "Cuota fija (en pesos)" && descuentoCuotaFija) || (descuento === "Veces Salario Mínimo (VSM) (UMI)" && descuentoVSM)) &&
      formaDePago && esquemaDeNomina && zona && cuotaDiaria && salarioBase && salarioBaseFecha && (siguenteDiaAPagarActive ? siguenteDiaAPagar : true);
    return isNominaValid;
  }

  const validateForm = () => {
    messageSubject.next('');
    if (!numeroDeNomina) {
      messageSubject.next('Todos los trabajadores requieren un numero de nomina');
      scrollIntoView(alertRef);
      return false
    }
    if (empresa.trabajadores.controlDePersonal.trabajadores.find((trabajador: any) => trabajador.info.numeroDeNomina === numeroDeNomina && numeroDeNomina !== currentTrabajadorCopy?.info.numeroDeNomina)) {
      messageSubject.next("Ya existe un trabajador con ese No de Nomina");
      scrollIntoView(alertRef);
      return false
    }
    if (!isDatosPersonalesValid()) {
      messageSubject.next('Parece que la seccion datos personales esta incompleta');
      scrollIntoView(alertRef);
      setStep(1);
      return false
    }
    if (!isContratoValid()) {
      messageSubject.next('Parece que la seccion contrato esta incompleta');
      scrollIntoView(alertRef);
      setStep(3);
      return false
    }
    if (!isHorarioValid()) {
      messageSubject.next('Parece que la seccion horario esta incompleta');
      scrollIntoView(alertRef);
      setStep(4);
      return false
    }
    if (!isNominaValid()) {
      messageSubject.next('Parece que la seccion nomina esta incompleta');
      scrollIntoView(alertRef);
      setStep(5);
      return false
    }
    return true;
  }

  const agregarFamiliar = () => {
    if (nombreDelFamiliar && tiposDeParentesco && telefonoBeneficiario && fechaDeNacimientoBeneficiario) {
      const newFamiliar = {
        nombreDelFamiliar: nombreDelFamiliar,
        parentesco: tiposDeParentesco,
        telefono: telefonoBeneficiario,
        fechaDeNacimiento: fechaDeNacimientoBeneficiario,
      }
      if (selectedItemBeneficiario) {
        const newBeneficiariosArray = beneficiariosArray.map((element: any) => {
          if (element.nombreDelFamiliar === selectedItemBeneficiario.nombreDelFamiliar && element.parentesco === selectedItemBeneficiario.parentesco && element.telefono === selectedItemBeneficiario.telefono) {
            return newFamiliar;
          }
          return element;
        });
        setBeneficiariosArray(newBeneficiariosArray);
        setSelectedItemBeneficiario(null);
      } else {
        setBeneficiariosArray([...beneficiariosArray, newFamiliar]);
      }
      setNombreDelFamiliar('');
      setTiposDeParentesco('Cónyuge');
      setTelefonoBeneficiario('');
      setFechaDeNacimientoBeneficiario(null);
    }
  }

  const onSelectBeneficiario = (element: any) => {
    setNombreDelFamiliar(element ? element.nombreDelFamiliar : '');
    setTiposDeParentesco(element ? element.parentesco : '');
    setTelefonoBeneficiario(element ? element.telefono : '');
    setFechaDeNacimientoBeneficiario(element ? element.fechaDeNacimiento : null);
    setSelectedItemBeneficiario(element || null);
  }

  const eliminarBeneficiario = () => {
    if (selectedItemBeneficiario) {
      const newBeneficiariosArray = beneficiariosArray.filter((element: any) => element.nombreDelFamiliar !== selectedItemBeneficiario.nombreDelFamiliar || element.parentesco !== selectedItemBeneficiario.parentesco || element.telefono !== selectedItemBeneficiario.telefono);
      setBeneficiariosArray(newBeneficiariosArray);
      setSelectedItemBeneficiario(null);
    }
  }

  useEffect(() => {
    const fetchUMA = async () => {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.uma,
      });
      if (error) {
        messageSubject.next("Hubo un error en el servidor");
        scrollIntoView(alertRef);
      }
      if (data && data.uma) {
        dispatch(
          actions.informacionGeneralActions.replaceInformacionGeneral({
            uma: data.uma,
            smg: data.smg,
            smf: data.smf,
            umi: data.umi,
          })
        );
      }
    };

    fetchUMA();
  }, []);

  useEffect(() => {
    if (fechaDeReconocimientoDeAntiguedad !== '' && cuotaDiaria !== '') {
      const difDay = getDifferenceInDays(fechaDeReconocimientoDeAntiguedad, new Date());
      const cuotaDiariaVar = parseFloat(cuotaDiaria + '');
      // if(Nomina.conceptos){

      // } else {
      let diasDeAquinaldo = empresa.empresa.prestacionesGenerales.aguinaldo.diasDeAguinaldo;
      if (empresa.empresa.prestacionesGenerales.aguinaldo.tipoDeAguinaldo === "Por Tabla") {
        for (const fecha of empresa.empresa.prestacionesGenerales.aguinaldo.tablaDeAguinaldo) {
          if (difDay.years >= fecha.primerAño && difDay.years < fecha.segundoAño) {
            diasDeAquinaldo = fecha.dias;
          }
        }
      }
      const aguinaldoSBC = (diasDeAquinaldo * cuotaDiariaVar / 365).toFixed(2);
      let diasDeVacaciones = 0;
      let primaVacacionalVar = empresa.empresa.prestacionesGenerales.primaVacacional.porcentajeDePrimaVacacional;
      if (empresa.empresa.prestacionesGenerales.primaVacacional.diasDeVacaciones === "Segun la Ley") {
        for (const periodo of informacionGeneral.diasDeVacacionesLey) {
          if (difDay.years >= periodo.primerAño && difDay.years < periodo.segundoAño) {
            diasDeVacaciones = periodo.diasDeVacaciones;
          }
        }
      } else {
        for (const periodo of empresa.empresa.prestacionesGenerales.primaVacacional.tablaDeDiasDeVacaiones) {
          if (difDay.years >= periodo.primerAño && difDay.years < periodo.segundoAño) {
            diasDeVacaciones = periodo.diasDeVacaciones;
            primaVacacionalVar = periodo.prima;
          }
        }
      }

      primaVacacionalVar = primaVacacionalVar / 100;

      const PrimaVacacionalSBC = ((diasDeVacaciones * primaVacacionalVar * cuotaDiariaVar) / 365).toFixed(2);

      const salarioBase = (cuotaDiariaVar + parseFloat(aguinaldoSBC) + parseFloat(PrimaVacacionalSBC)).toFixed(2);

      setSalarioBase(salarioBase);
      setActiveButtonNomina(fechaDeReconocimientoDeAntiguedad && salarioBase);
      setArregloDesgloceSalario([
        { label: 'Años de Antiguedad:', value: difDay.years, type: 'Normal' },
        { label: 'Días de Aguinaldo:', value: diasDeAquinaldo, type: 'Normal' },
        { label: 'Cuota Diaria:', value: cuotaDiariaVar, type: 'Normal' },
        { label: 'Aguinaldo SBC:', value: aguinaldoSBC, type: 'Bold' },
        { label: 'Prima Vacacional:', value: primaVacacionalVar + '%', type: 'Normal' },
        { label: 'Días de Vacaciones:', value: diasDeVacaciones, type: 'Normal' },
        { label: 'Prima Vacaciona SBC:', value: PrimaVacacionalSBC, type: 'Bold' },
        { label: 'Salario Base Suguerido:', value: salarioBase, type: 'Result' },
      ]);

      // }
    }
  }, [cuotaDiaria, salarioBase]);

  const generateNewID = () => {
    const id = getRandomID();
    const departamentos = empresa.trabajadores.controlDePersonal.trabajadores.map((departamento: {
      id: string;
      nombreDeDepartamento: string;
      IMSSorEstatal: string;
      dependeDe: string;
    }) => {
      return departamento.id
    })
    if (departamentos.includes(id)) {
      generateNewID()
    } else {
      return id
    }
  }

  const postIMG = async (newRegistro : any, useCurrentIMG: boolean = false) => {
    const name = currentEmpresa.nombre + newRegistro.datosPersonales.datosPersonales.nombre + newRegistro.datosPersonales.datosPersonales.apellidoPaterno + newRegistro.datosPersonales.datosPersonales.apellidoMaterno + getRandomID()
    if (userImage) {
      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.postIMG,
        data: {
          nombre: name,
          img: useCurrentIMG ? currentTrabajadorCopy.datosPersonales.datosPersonales.userImageB64 : userImage,
        },
      });
  
      if (error) {
        messageSubject.next("Hubo un error en el servidor");
        scrollIntoView(alertRef);
      }
      if (data) {
        newRegistro.datosPersonales.datosPersonales.userImage = data.imgName;
      }
    } else {
      newRegistro.datosPersonales.datosPersonales.userImage = '';
    }
    return newRegistro;
  }

  const updateIMG = async (newRegistro : any) => {
    const name = currentEmpresa.nombre + newRegistro.datosPersonales.datosPersonales.nombre + newRegistro.datosPersonales.datosPersonales.apellidoPaterno + newRegistro.datosPersonales.datosPersonales.apellidoMaterno + getRandomID()
    if (currentTrabajadorCopy.datosPersonales.datosPersonales.userImage !== '') {
      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.removeIMG,
        data: {
          nombre: currentTrabajadorCopy.datosPersonales.datosPersonales.userImage,
        },
      });
      if (error) {
        return { message: "Hubo un error en el servidor" };
      }
      if (data) {
        if (data && data.message === "Proceed") {
          newRegistro.datosPersonales.datosPersonales.userImage = '';
        }
    }
  }
    if (userImage) {
      newRegistro = await postIMG(newRegistro);
    } else if (currentTrabajadorCopy.datosPersonales.datosPersonales.userImageB64) {
      newRegistro = await postIMG(newRegistro, true);
    }
    return newRegistro;
  }

const editarTrabajador = async () => {
  let newRegistro = getAllInfo();
      if (newRegistro.nomina.historialCompletoArray[newRegistro.nomina.historialCompletoArray.length - 1].cuotaDiaria !== newRegistro.nomina.cuotaDiaria
        || getDifferenceInDays(newRegistro.nomina.historialCompletoArray[newRegistro.nomina.historialCompletoArray.length - 1].fecha, newRegistro.nomina.salarioBaseFecha).days
        || newRegistro.nomina.historialCompletoArray[newRegistro.nomina.historialCompletoArray.length - 1].salarioBase !== newRegistro.nomina.salarioBase) {
        newRegistro.nomina.historialCompletoArray.push({ fechaDeEdicion: new Date(), fecha: newRegistro.nomina.salarioBaseFecha, cuotaDiaria: newRegistro.nomina.cuotaDiaria, salarioBase: newRegistro.nomina.salarioBase })
      }
      if (currentTrabajadorCopy.info.status === 'INACTIVO') {
        newRegistro.info.status = 'ACTIVO';
        newRegistro.contrato.historialDeBajasYReIngresos.push({ typo: 'Alta', fecha: new Date() });
      }
      newRegistro = await updateIMG(newRegistro);
      postForm(newRegistro, true);
}

  const crearTrabajador = async () => {
    let newRegistro = getAllInfo();
    newRegistro = await postIMG(newRegistro);
    newRegistro.nomina.historialCompletoArray.push({ fechaDeEdicion: new Date(), fecha: newRegistro.nomina.salarioBaseFecha, cuotaDiaria: newRegistro.nomina.cuotaDiaria, salarioBase: newRegistro.nomina.salarioBase })
    postForm(newRegistro);
  }

  const postForm = async (newRegistro: any, editar: boolean = false) => {
    const [error, res] = await apiRequest({
      method: "post",
      url: editar ? apiRoutes.editarTrabajador : apiRoutes.crearTrabajador,
      data: {
        trabajador: newRegistro,
        id: currentEmpresa.id,
      },
    });

    if (error) {
      messageSubject.next("Hubo un error en el servidor");
      scrollIntoView(alertRef);
    }
    if (res) {
      if (res.message !== "Proceed") {
        messageSubject.next(res.message);
        scrollIntoView(alertRef);
      } else {
        messageSubject.next('');
        successfulSubject.next('La Informacion se agrego correctamente a la base de datos');
        dispatch(
          actions.currentTrabajador.replaceCurrentTrabajador(null)
        );
        let newTrabadores;
        if(currentTrabajadorCopy){
          newTrabadores = empresa.trabajadores.controlDePersonal.trabajadores.map((trabajador: any) => {
            if (trabajador.info.numeroDeNomina === res.trabajador.info.numeroDeNomina) {
              return res.trabajador;
            }
            return trabajador;
          });
        } else {
          newTrabadores = [...empresa.trabajadores.controlDePersonal.trabajadores, res.trabajador]
        }
        dispatch(
          actions.empresaActions.replaceEmpresa({
            ...empresa,
            trabajadores: {
              ...empresa.trabajadores,
              controlDePersonal: {
                ...empresa.trabajadores.controlDePersonal,
                trabajadores: [...newTrabadores]
              }
            }
          }));
        history(paths.ControlDePersonal);
      }
    }
  }

  const getAllInfo = () => {
    const newID = generateNewID()
    const NuevoEmpleado = {
      info: currentTrabajadorCopy ?
        { ...currentTrabajadorCopy.info, numeroDeNomina: numeroDeNomina !== '' ? numeroDeNomina : currentTrabajadorCopy.info.numeroDeNomina } :
        { id: newID, numeroDeNomina, status: "ACTIVO" },
      datosPersonales: getDatosPersonalesDataObject(),
      beneficiarios: beneficiariosArray,
      contrato: getContratoDataObject(),
      horario: getHorarioDataObject(),
      nomina: getNominaDataObject(),
    }
    return NuevoEmpleado
  }

  const getDatosPersonalesDataObject = () => {
    return {
      datosPersonales: {
        rfc,
        curp,
        apellidoPaterno,
        apellidoMaterno,
        nombre,
        correoElectronico,
        telefonoMovil,
        nacionalidad,
        lugarDeNacimiento,
        entidadFederativa,
        fechaDeNacimiento,
        sexo,
        estadoCivil,
        numeroDelIMSS,
        tipoDePension,
        escolaridad,
        permitirLlenado,
        tipoDeLlenado,
        userImage: userImage ? userImage : ''
      },
      domicilioFiscal: { ...domicilioFiscalForm }
    };
  }

  const getContratoDataObject = () => {
    let contratoToReturn: trabajadorContrato = {
      fechaDeIngreso,
      fechaDeExpiracion,
      fechaDeReconocimientoDeAntiguedad,
      monto,
      departamento,
      tipoDeContrato,
      puestoActual,
      puestoDeConfianza,
      causaQueDaOrigen,
      historialDeBajasYReIngresos: currentTrabajadorCopy?.contrato?.historialDeBajasYReIngresos || []
    }
    if (currentTrabajadorCopy && currentTrabajadorCopy.contrato?.historialDeBajasYReIngresos.length <= 0){
      contratoToReturn.historialDeBajasYReIngresos.push({typo: 'Alta', fecha: new Date()})
    }
    return contratoToReturn
  }

  const getNominaDataObject = () => {
    return {
      formaDePago,
      bancoDespensa,
      tarjetaDespensa,
      formaDePagoCuenta,
      esquemaDeNomina,
      siguenteDiaAPagar,
      zona,
      diasPendientesDeDisfrutar,
      creditoInfonavit,
      fechaInfonavit,
      descuento,
      descuentoPorcentaje,
      descuentoCuotaFija,
      descuentoVSM,
      cuotaDiaria,
      salarioBase,
      salarioBaseFecha,
      historialCompletoArray,
      variablesIMSSArray: [],
      omitirSubsidio,
      arregloDesgloceSalario
    };
  }

  const getHorarioDataObject = () => {
    const horarioToSend = getHorarioData();
    let horariosToReturn = [];
    if (currentTrabajadorCopy && currentTrabajadorCopy.horario) {
      horariosToReturn = currentTrabajadorCopy.horario;
      horariosToReturn[currentHorrarioIndex] = horarioToSend;
    } else {
      horariosToReturn = [horarioToSend];
    }
    return horariosToReturn
  }

  const getHorarioData = () => {
    const secuenciaDeEntradasYSalidasHSent = secuenciaDeEntradasYSalidasH && {
      lunes: secuenciaDeEntradasYSalidasH['lunes'] ? secuenciaDeEntradasYSalidasH['lunes'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
      martes: secuenciaDeEntradasYSalidasH['martes'] ? secuenciaDeEntradasYSalidasH['martes'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
      miercoles: secuenciaDeEntradasYSalidasH['miercoles'] ? secuenciaDeEntradasYSalidasH['miercoles'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
      jueves: secuenciaDeEntradasYSalidasH['jueves'] ? secuenciaDeEntradasYSalidasH['jueves'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
      viernes: secuenciaDeEntradasYSalidasH['viernes'] ? secuenciaDeEntradasYSalidasH['viernes'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
      sabado: secuenciaDeEntradasYSalidasH['sabado'] ? secuenciaDeEntradasYSalidasH['sabado'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
      domingo: secuenciaDeEntradasYSalidasH['domingo'] ? secuenciaDeEntradasYSalidasH['domingo'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
    }
    const secuenciaDeEntradasYSalidasSSent = [];
    for (const index in secuenciaDeEntradasYSalidasS) {
      secuenciaDeEntradasYSalidasSSent.push({
        horario: secuenciaDeEntradasYSalidasS[index].horario,
        lunes: secuenciaDeEntradasYSalidasS[index]['lunes'] ? secuenciaDeEntradasYSalidasS[index]['lunes'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
        martes: secuenciaDeEntradasYSalidasS[index]['martes'] ? secuenciaDeEntradasYSalidasS[index]['martes'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
        miercoles: secuenciaDeEntradasYSalidasS[index]['miercoles'] ? secuenciaDeEntradasYSalidasS[index]['miercoles'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
        jueves: secuenciaDeEntradasYSalidasS[index]['jueves'] ? secuenciaDeEntradasYSalidasS[index]['jueves'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
        viernes: secuenciaDeEntradasYSalidasS[index]['viernes'] ? secuenciaDeEntradasYSalidasS[index]['viernes'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
        sabado: secuenciaDeEntradasYSalidasS[index]['sabado'] ? secuenciaDeEntradasYSalidasS[index]['sabado'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
        domingo: secuenciaDeEntradasYSalidasS[index]['domingo'] ? secuenciaDeEntradasYSalidasS[index]['domingo'].map((horario: horarios) => { return { omitirEntrada: horario.omitirEntrada, omitirSalida: horario.omitirSalida } }) : [],
      })
    }

    const horarioToSend = {
      usarSecuenciaDeHorarios,
      secuencia: secuencia !== "Sin secuencia" ? empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((secuenciaVar: any) => secuenciaVar.clave === secuencia)[0].id : "Sin secuencia",
      horario: horario !== "Sin horario" ? empresa.empresa.horariosYControlDeAsistencia.horarios.filter((horarioVar: any) => horarioVar.clave === horario)[0].id : "Sin horario",
      considerarTiempoLaboradoEnHorarioDeComida,
      considerarEntradasAnticipadasComoTiempoExtra,
      tiempoXTiempo,
      secuenciaDeEntradasYSalidasH: secuenciaDeEntradasYSalidasHSent,
      secuenciaDeEntradasYSalidasS: secuenciaDeEntradasYSalidasSSent,
      fechaDeInicioEspecial,
      cicloDeInicioEspecial,
      usarHorarioEspecial,
      pagarHorasExtra,
      periodo,
      horaDeInicio
    };
    return horarioToSend;
  }

  const eliminarUltimoHorarioTrabajador = () => {
    const currentTrabajadorTemporalCopy = deepCopy(currentTrabajadorCopy);
    currentTrabajadorTemporalCopy.horario[currentTrabajadorTemporalCopy.horario.length - 2].fechaDeFinalizacion = null;
    delete currentTrabajadorTemporalCopy.horario[currentTrabajadorTemporalCopy.horario.length - 1];
    currentTrabajadorTemporalCopy.horario = currentTrabajadorTemporalCopy.horario.filter(Boolean);
    // replaceData();
    setCurrentTrabajadorCopy({ ...currentTrabajadorTemporalCopy, });
    setCurrentHorrarioIndex(currentHorrarioIndex - 1);
    setUltimaFecha(currentTrabajadorTemporalCopy.horario.length > 1 ? currentTrabajadorTemporalCopy.horario[currentTrabajadorTemporalCopy.horario.length - 2].fechaDeFinalizacion : new Date());
    setJustAdded(true);
  }

  const setArrayHorariosEspecialesFunction = () => {
    if (usarHorarioEspecial && horario !== 'Sin horario') {
      const currentHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => {
        return H.clave === horario
      });
      setUsarHorarioEspecial(currentHorario.length > 0 && currentHorario[currentHorario.length - 1]['horarioEspecial'] && currentHorario[currentHorario.length - 1]['horarioEspecial']['horariosEspecialesArray']);
      if (usarHorarioEspecial) {
        setArregloDeCiclosEspecial(currentHorario[currentHorario.length - 1]['horarioEspecial']['horariosEspecialesArray'] &&currentHorario[currentHorario.length - 1]['horarioEspecial']['horariosEspecialesArray'].map((horario: any, index: number) => 'Periodo: ' + (index + 1) + ' Trabajadas: ' + horario.trabajadas + ' Descanso: ' + horario.descanso));
      }
    }
  }

  const setSecuencias = (horario: string, secuenciaH: any, secuencia: string, secuenciaS: any, usarSecuenciaDeHorariosVar: any) => {
    let currentHorario = [];
    let currentHorarioObject = {};
    if (horario !== 'Sin horario') {
      currentHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => {
        return H.id === horario
      });
      currentHorarioObject = currentHorario.length > 0 ? {
        lunes: currentHorario[currentHorario.length - 1].lunes.horarios ? currentHorario[currentHorario.length - 1].lunes.horarios.map((horario: horarios, index: number) => { return { entrada: presentarNumero(horario.entrada), salida: presentarNumero(horario.salida), omitirEntrada: secuenciaH.lunes[index].omitirEntrada, omitirSalida: secuenciaH.lunes[index].omitirSalida } }) : [],
        martes: currentHorario[currentHorario.length - 1].martes.horarios ? currentHorario[currentHorario.length - 1].martes.horarios.map((horario: horarios, index: number) => { return { entrada: presentarNumero(horario.entrada), salida: presentarNumero(horario.salida), omitirEntrada: secuenciaH.martes[index].omitirEntrada, omitirSalida: secuenciaH.martes[index].omitirSalida } }) : [],
        miercoles: currentHorario[currentHorario.length - 1].miercoles.horarios ? currentHorario[currentHorario.length - 1].miercoles.horarios.map((horario: horarios, index: number) => { return { entrada: presentarNumero(horario.entrada), salida: presentarNumero(horario.salida), omitirEntrada: secuenciaH.miercoles[index].omitirEntrada, omitirSalida: secuenciaH.miercoles[index].omitirSalida } }) : [],
        jueves: currentHorario[currentHorario.length - 1].jueves.horarios ? currentHorario[currentHorario.length - 1].jueves.horarios.map((horario: horarios, index: number) => { return { entrada: presentarNumero(horario.entrada), salida: presentarNumero(horario.salida), omitirEntrada: secuenciaH.jueves[index].omitirEntrada, omitirSalida: secuenciaH.jueves[index].omitirSalida } }) : [],
        viernes: currentHorario[currentHorario.length - 1].viernes.horarios ? currentHorario[currentHorario.length - 1].viernes.horarios.map((horario: horarios, index: number) => { return { entrada: presentarNumero(horario.entrada), salida: presentarNumero(horario.salida), omitirEntrada: secuenciaH.viernes[index].omitirEntrada, omitirSalida: secuenciaH.viernes[index].omitirSalida } }) : [],
        sabado: currentHorario[currentHorario.length - 1].sabado.horarios ? currentHorario[currentHorario.length - 1].sabado.horarios.map((horario: horarios, index: number) => { return { entrada: presentarNumero(horario.entrada), salida: presentarNumero(horario.salida), omitirEntrada: secuenciaH.sabado[index].omitirEntrada, omitirSalida: secuenciaH.sabado[index].omitirSalida } }) : [],
        domingo: currentHorario[currentHorario.length - 1].domingo.horarios ? currentHorario[currentHorario.length - 1].domingo.horarios.map((horario: horarios, index: number) => { return { entrada: presentarNumero(horario.entrada), salida: presentarNumero(horario.salida), omitirEntrada: secuenciaH.domingo[index].omitirEntrada, omitirSalida: secuenciaH.domingo[index].omitirSalida } }) : [],
      } : {}
      setSecuenciaDeEntradasYSalidasS(currentHorarioObject);
    }
    let currentSecuencia = [];
    let HorName = '';
    let currentSecuenciaObject = [];
    if (usarSecuenciaDeHorariosVar) {
      currentSecuencia = empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => {
        return S.id === secuencia
      });
      if (currentSecuencia.length > 0) {
        currentSecuencia = currentSecuencia[currentSecuencia.length - 1]['secuencia'].map((S: any) => {
          return S.clave
        })
      }
      const currentSecuenciaSet = getUniqueElements(currentSecuencia);
      for (const IN in currentSecuenciaSet) {
        let currentHorario = [];
        let currentHorarioObject = {};
        currentHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => {
          return H.clave === currentSecuenciaSet[IN]
        });
        HorName = currentHorario[currentHorario.length - 1]['clave']
        currentHorarioObject = {
          lunes: currentHorario[currentHorario.length - 1].lunes.horarios ? currentHorario[currentHorario.length - 1].lunes.horarios.map((horarioVar: horarios, indexVar: number) => { 
            return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: secuenciaS[IN].lunes[indexVar].omitirEntrada, omitirSalida: secuenciaS[IN].lunes[indexVar].omitirSalida } 
          }) : [],
          martes: currentHorario[currentHorario.length - 1].martes.horarios ? currentHorario[currentHorario.length - 1].martes.horarios.map((horarioVar: horarios, indexVar: number) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: secuenciaS[IN].martes[indexVar].omitirEntrada, omitirSalida: secuenciaS[IN].martes[indexVar].omitirSalida } }) : [],
          miercoles: currentHorario[currentHorario.length - 1].miercoles.horarios ? currentHorario[currentHorario.length - 1].miercoles.horarios.map((horarioVar: horarios, indexVar: number) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: secuenciaS[IN].miercoles[indexVar].omitirEntrada, omitirSalida: secuenciaS[IN].miercoles[indexVar].omitirSalida } }) : [],
          jueves: currentHorario[currentHorario.length - 1].jueves.horarios ? currentHorario[currentHorario.length - 1].jueves.horarios.map((horarioVar: horarios, indexVar: number) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: secuenciaS[IN].jueves[indexVar].omitirEntrada, omitirSalida: secuenciaS[IN].jueves[indexVar].omitirSalida } }) : [],
          viernes: currentHorario[currentHorario.length - 1].viernes.horarios ? currentHorario[currentHorario.length - 1].viernes.horarios.map((horarioVar: horarios, indexVar: number) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: secuenciaS[IN].viernes[indexVar].omitirEntrada, omitirSalida: secuenciaS[IN].viernes[indexVar].omitirSalida } }) : [],
          sabado: currentHorario[currentHorario.length - 1].sabado.horarios ? currentHorario[currentHorario.length - 1].sabado.horarios.map((horarioVar: horarios, indexVar: number) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: secuenciaS[IN].sabado[indexVar].omitirEntrada, omitirSalida: secuenciaS[IN].sabado[indexVar].omitirSalida } }) : [],
          domingo: currentHorario[currentHorario.length - 1].domingo.horarios ? currentHorario[currentHorario.length - 1].domingo.horarios.map((horarioVar: horarios, indexVar: number) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: secuenciaS[IN].domingo[indexVar].omitirEntrada, omitirSalida: secuenciaS[IN].domingo[indexVar].omitirSalida } }) : [],
        }
        currentSecuenciaObject.push({ ...currentHorarioObject, horario: HorName })
      }
      setSecuenciaDeEntradasYSalidasS(currentSecuenciaObject);
    }
  }

  const replaceData = () => {
    if(currentTrabajadorCopy){
      const currentTrabajadorHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.id === currentTrabajadorCopy.horario[currentHorrarioIndex].horario && !H.inactivo)[0] ? empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => H.id === currentTrabajadorCopy.horario[currentHorrarioIndex].horario && !H.inactivo)[0].clave : "Sin horario";
      const currentTrabajadorSecuencia = empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => S.id === currentTrabajadorCopy.horario[currentHorrarioIndex].secuencia && !S.inactivo)[0] ? empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => S.id === currentTrabajadorCopy.horario[currentHorrarioIndex].secuencia && !S.inactivo)[0].clave : "Sin secuencia";
      const horarioVar = currentTrabajadorCopy.horario[currentHorrarioIndex];
      setUsarSecuenciaDeHorarios(horarioVar['usarSecuenciaDeHorarios']);
      setSecuencia(currentTrabajadorSecuencia);
      setHorario(currentTrabajadorHorario);
      setConsiderarTiempoLaboradoEnHorarioDeComida(horarioVar['considerarTiempoLaboradoEnHorarioDeComida']);
      setConsiderarEntradasAnticipadasComoTiempoExtra(horarioVar['considerarEntradasAnticipadasComoTiempoExtra']);
      setTiempoXTiempo(horarioVar['tiempoXTiempo'] ? horarioVar['tiempoXTiempo'] : '');
      setSecuenciaDeEntradasYSalidasH(horarioVar['secuenciaDeEntradasYSalidasH'] ? horarioVar['secuenciaDeEntradasYSalidasH'] : null);
      setSecuenciaDeEntradasYSalidasS(horarioVar['secuenciaDeEntradasYSalidasS'] ? horarioVar['secuenciaDeEntradasYSalidasS'] : null);
      setPagarHorasExtra(horarioVar['pagarHorasExtra'] ? horarioVar['pagarHorasExtra'] : false);
      setCicloDeInicioEspecial(horarioVar['cicloDeInicioEspecial'] ? horarioVar['cicloDeInicioEspecial'] : '');
      setFechaDeInicioEspecial(horarioVar['fechaDeInicioEspecial'] ? horarioVar['fechaDeInicioEspecial'] : '');
      setHoraDeInicio(horarioVar['horaDeInicio'] ? horarioVar['horaDeInicio'] : '');
      setPeriodo(horarioVar['periodo'] ? horarioVar['periodo'] : null);
      setUsarHorarioEspecial(horarioVar.usarHorarioEspecial);
      setArrayHorariosEspecialesFunction();
      setSecuencias(horarioVar.horario, horarioVar.secuenciaDeEntradasYSalidasH, horarioVar.secuencia, horarioVar.secuenciaDeEntradasYSalidasS, horarioVar.usarSecuenciaDeHorarios);
    }
  }

  useEffect(() => {
    replaceData();
  }, [currentHorrarioIndex]);

  const onValueSecuenciaDropdown = (secuencia: string) => {
    setSecuencia(secuencia)
    let currentSecuencia = [];
    let horName = '';
    let currentSecuenciaObject: any = [];
    if (usarSecuenciaDeHorarios) {
      currentSecuencia = empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((S: any) => {
        return S.clave === secuencia
      });
      currentSecuencia = currentSecuencia[currentSecuencia.length - 1]['secuencia'].map((S: any) => {
        return S.clave
      })
      const currentSecuenciaSet = getUniqueElements(currentSecuencia);
      for (const horario of currentSecuenciaSet) {
        let currentHorario = [];
        let currentHorarioObject = {};
        currentHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => {
          return H.clave === horario
        });
        horName = currentHorario[currentHorario.length - 1]['clave']
        currentHorarioObject = {
          lunes: currentHorario[currentHorario.length - 1].lunes.horarios ? currentHorario[currentHorario.length - 1].lunes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          martes: currentHorario[currentHorario.length - 1].martes.horarios ? currentHorario[currentHorario.length - 1].martes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          miercoles: currentHorario[currentHorario.length - 1].miercoles.horarios ? currentHorario[currentHorario.length - 1].miercoles.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          jueves: currentHorario[currentHorario.length - 1].jueves.horarios ? currentHorario[currentHorario.length - 1].jueves.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          viernes: currentHorario[currentHorario.length - 1].viernes.horarios ? currentHorario[currentHorario.length - 1].viernes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          sabado: currentHorario[currentHorario.length - 1].sabado.horarios ? currentHorario[currentHorario.length - 1].sabado.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
          domingo: currentHorario[currentHorario.length - 1].domingo.horarios ? currentHorario[currentHorario.length - 1].domingo.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        }
        currentSecuenciaObject.push({ ...currentHorarioObject, horario: horName })
      }
    }
    setSecuenciaDeEntradasYSalidasS(currentSecuenciaObject);
  }

  const onValueHorarioDropdown = (horario: string) => {
    let usarHorarioEspecialTemporal = false;
    setHorario(horario);
    setCicloDeInicioEspecial('');
    setFechaDeInicioEspecial(null);
    let currentHorario = [];
    let currentHorarioObject = {};
    if (horario !== 'Sin horario') {
      currentHorario = empresa.empresa.horariosYControlDeAsistencia.horarios.filter((H: any) => {
        return H.clave === horario
      });
      usarHorarioEspecialTemporal = currentHorario[currentHorario.length - 1]?.['horarioEspecial']?.['horariosEspecialesArray'];
      if (usarHorarioEspecialTemporal) {
        setArregloDeCiclosEspecial(currentHorario[currentHorario.length - 1]['horarioEspecial']['horariosEspecialesArray'].map((horarioVar: any, index: number) => 'Periodo: ' + (index + 1) + ' Trabajadas: ' + horarioVar.trabajadas + ' Descanso: ' + horarioVar.descanso));
      }
      currentHorarioObject = {
        lunes: currentHorario[currentHorario.length - 1].lunes.horarios ? currentHorario[currentHorario.length - 1].lunes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        martes: currentHorario[currentHorario.length - 1].martes.horarios ? currentHorario[currentHorario.length - 1].martes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        miercoles: currentHorario[currentHorario.length - 1].miercoles.horarios ? currentHorario[currentHorario.length - 1].miercoles.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        jueves: currentHorario[currentHorario.length - 1].jueves.horarios ? currentHorario[currentHorario.length - 1].jueves.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        viernes: currentHorario[currentHorario.length - 1].viernes.horarios ? currentHorario[currentHorario.length - 1].viernes.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        sabado: currentHorario[currentHorario.length - 1].sabado.horarios ? currentHorario[currentHorario.length - 1].sabado.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
        domingo: currentHorario[currentHorario.length - 1].domingo.horarios ? currentHorario[currentHorario.length - 1].domingo.horarios.map((horarioVar: horarios) => { return { entrada: presentarNumero(horarioVar.entrada), salida: presentarNumero(horarioVar.salida), omitirEntrada: false, omitirSalida: false } }) : [],
      }
    }
    setUsarHorarioEspecial(usarHorarioEspecialTemporal);
    setSecuenciaDeEntradasYSalidasH(currentHorarioObject);
  }

  const addEndDate = () => {
    const currentTrabajadorTemporalCopy = deepCopy(currentTrabajadorCopy);
    currentTrabajadorTemporalCopy.horario[currentHorrarioIndex].fechaDeFinalizacion = selectedDayModalCambioDeHorario;
    currentTrabajadorTemporalCopy.horario.push({
      usarSecuenciaDeHorarios: false,
      secuencia: '',
      horario: 'Sin horario',
      considerarTiempoLaboradoEnHorarioDeComida: false,
      considerarEntradasAnticipadasComoTiempoExtra: false,
      tiempoXTiempo: '',
      secuenciaDeEntradasYSalidasH: {},
      secuenciaDeEntradasYSalidasS: {},
      fechaDeInicioEspecial: "",
      cicloDeInicioEspecial: "",
      periodo: null,
      horaDeInicio: "",
      pagarHorasExtra: false,
    });
    const newCurrentHorarioIndex = currentTrabajadorTemporalCopy.horario.length - 1;
    setCurrentHorrarioIndex(newCurrentHorarioIndex);
    resetHorario();
    setDisableHorarios(false);
    setUsarHorarioEspecial(false);
    setUltimaFecha(currentTrabajadorTemporalCopy.horario[currentTrabajadorTemporalCopy.horario.length - 2].fechaDeFinalizacion);
    setJustAdded(true);
    setModalCambioDeHorarioOpen(false);
    setCurrentTrabajadorCopy(deepCopy(currentTrabajadorTemporalCopy));
    setDisableHorarios(!(newCurrentHorarioIndex > currentTrabajadorCopy.horario.length - 1));
  }

  const resetHorario = () => {
    setUsarSecuenciaDeHorarios(false);
    setSecuencia('Sin secuencia');
    setHorario('Sin horario');
    setConsiderarTiempoLaboradoEnHorarioDeComida(false);
    setConsiderarEntradasAnticipadasComoTiempoExtra(false);
    setTiempoXTiempo('');
    setSecuenciaDeEntradasYSalidasH(null);
    setSecuenciaDeEntradasYSalidasS(null);
    setFechaDeInicioEspecial('');
    setCicloDeInicioEspecial('');
    setPeriodo(null);
    setHoraDeInicio('');
    setPagarHorasExtra(false);
  }

  const cancelEndDate = () => {
    setSelectedDayModalCambioDeHorario(sumOneDay(ultimaFecha));
    setModalCambioDeHorarioOpen(false);
  }

  const arrowLeft = () => {
    if (currentHorrarioIndex > 0) {
      if (currentHorrarioIndex === currentTrabajadorCopy.horario.length - 1) {
        currentTrabajadorCopy.horario[currentHorrarioIndex] = getHorarioData();
      }
      const newCurrentHorarioIndex = currentHorrarioIndex !== 0 ? currentHorrarioIndex - 1 : 0;
      setCurrentHorrarioIndex(newCurrentHorarioIndex);
      setDisableHorarios(newCurrentHorarioIndex !== currentTrabajadorCopy.horario.length - 1 || !justAdded);
      // replaceData();
    }
  }

  const arrowRight = () => {
    if (currentTrabajadorCopy && currentTrabajadorCopy.horario && currentTrabajadorCopy.horario.length - 1 > currentHorrarioIndex) {
      setCurrentHorrarioIndex(currentTrabajadorCopy && currentTrabajadorCopy.horario && currentTrabajadorCopy.horario.length - 1 > currentHorrarioIndex ? currentHorrarioIndex + 1 : currentTrabajadorCopy.horario.length - 1);
      setDisableHorarios(currentHorrarioIndex !== currentTrabajadorCopy.horario.length - 1 || !justAdded);
      // replaceData();
    }
  }

  useEffect(() => {
    const alreadyImportedTrabajador = empresa.trabajadores.controlDePersonal.trabajadores.find((trabajador: any) => 
    {return trabajador.datosPersonales.datosPersonales.curp === curp && trabajador.datosPersonales.datosPersonales.rfc === rfc}
    );
    if(alreadyImportedTrabajador && !currentTrabajadorCopy){
      alreadyImported(alreadyImportedTrabajador);
      warningSubject.next('Este RFC ya existe, actualizamos el trabajador por el que estas buscando');
    }
  }, [curp, rfc]);

  return (
    <div>
      <DivTitulos>
        <DivTitulosTitulo>TRABAJADORES</DivTitulosTitulo>
        <DivTitulosSubTitulo>
          NUEVO EMPLEADO
        </DivTitulosSubTitulo>
      </DivTitulos>
      <div ref={alertRef} style={{margin: '0px 35px'}}>
        <Warning
          type={"Alert"}
          title={"Alerta"}
          warningSubject={messageSubject}
        />
        <Warning
          type={"Warning"}
          title={"Advertencia"}
          warningSubject={warningSubject}
        />
        <Warning
          type={"Successful"}
          title={"Exito!"}
          warningSubject={successfulSubject}
        />
      </div>
      {!smallViewPort && <ContenedorSteps>
        <Step activeclass={step === 1} onClick={() => setStep(1)}>Datos Personales</Step>
        <Step activeclass={step === 2} onClick={() => setStep(2)}>Beneficiarios</Step>
        <Step activeclass={step === 3} onClick={() => setStep(3)}>Contrato</Step>
        <Step activeclass={step === 4} onClick={() => setStep(4)}>Horario</Step>
        <Step activeclass={step === 5} onClick={() => setStep(5)}>Nomina</Step>
      </ContenedorSteps>}
      <ContenedorNumeroDeNomina>
        <TextLabelNomina>
          No de Nomina<Obligatorio>*</Obligatorio>
        </TextLabelNomina>
        <InputGrandeNomina
          type="text"
          value={numeroDeNomina}
          onChange={(e) => setNumeroDeNomina(e.target.value)}
          onBlur={() =>  onNoNominaBlur()}
        />
      </ContenedorNumeroDeNomina>
      {(step === 1 || smallViewPort) && <ContenedorSection>
        <TextLabelTitle>DATOS PERSONALES</TextLabelTitle>
        <ChevronTabIcon
          tabOpen={tabOpenDatosPersonales}
          toggleTab={() => setTabOpenDatosersonales(!tabOpenDatosPersonales)}
        />
        {(tabOpenDatosPersonales || !smallViewPort && step === 1) && (
          <>
            {curpIsInvalid && <Alert>El CURP es requerido</Alert>}
            {curpLengthIsInvalid && <Alert>El CURP debe tener exactamente 18 caracteres</Alert>}
            {rfcIsInvalid && <Alert>El R.F.C. es requerido</Alert>}
            {rfcLengthIsInvalid && <Alert>El R.F.C. debe tener exactamente 13 caracteres</Alert>}
            <FlexRow style={{ columnGap: "30px" }}>
              <Flex1>
                <TextLabel>
                  CURP<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={curp}
                  onChange={(e) => setCURP(e.target.value.replace(regex, "").toUpperCase())}
                  onBlur={() => CURPFocusOut()}
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  R.F.C<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={rfc}
                  onChange={(e) => setRFC(e.target.value.toUpperCase())}
                />
              </Flex1>
            </FlexRow>
            {!apellidoPaternoValid && <Alert>El Apellido Paterno es requerido</Alert>}
            {!apellidoMaternoValid && <Alert>El Apellido Materno es requerido</Alert>}
            {!nombreValid && <Alert>El Nombre es requerido</Alert>}
            <FlexRow style={{ columnGap: "30px" }}>
              <Flex1>
                <FlexColumn>
                  <TextLabel>
                    Apellido Paterno<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={apellidoPaterno}
                    onChange={(e) => setApellidoPaterno(e.target.value)}
                    onBlur={(e) => setApellidoPaternoValid(e.target.value !== "")}
                  />
                  <TextLabel>
                    Apellido Materno<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={apellidoMaterno}
                    onChange={(e) => setApellidoMaterno(e.target.value)}
                    onBlur={(e) => setApellidoMaternoValid(e.target.value !== "")}
                  />
                  <TextLabel>
                    Nombre<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    onBlur={(e) => setNombreValid(e.target.value !== "")}
                  />
                </FlexColumn>
              </Flex1>
              <Flex1>
                <DivFotoContainer>
                  <DivUserFoto
                    style={{
                      backgroundImage: `url(${userImage
                        ? userImage
                        : NoUser
                        }`,
                    }}
                  ></DivUserFoto>
                  {!userImage && (
                    <SecondButtonStyleList
                    >
                      <Inputfile
                        onChange={(e: any) => onSelectFile(e)}
                        type="file"
                        name="file"
                        id="file"
                        accept="image/*"
                      />
                      <label
                        htmlFor="file"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        AGREGAR IMAGEN
                      </label>
                    </SecondButtonStyleList>
                  )}
                  {userImage && (
                    <SecondButtonStyleList
                      onClick={() => removeIMG()}
                    >
                      ELIMINAR IMAGEN
                    </SecondButtonStyleList>
                  )}
                </DivFotoContainer>
              </Flex1>
            </FlexRow>
            {!isCorreoElectronicoValid && correoElectronico && (
              <Alert>Este correo no es un correo válido</Alert>
            )}
            <FlexRow style={{ columnGap: "30px", paddingBottom: '0px' }}>
              <Flex1>
                <TextLabel>
                  Correo Electronico
                </TextLabel>
                <InputGrande
                  value={correoElectronico}
                  type="email"
                  autoComplete="off"
                  onChange={(e: any) =>
                    omit_special_char_email(e.target.value)
                  }
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  Teléfono móvil
                </TextLabel>
                <PhoneInput
                  inputValue={telefonoMovil}
                  onChange={(value: string) => setTelefonoMovil(value)}
                />
              </Flex1>
            </FlexRow>
            <FlexRow style={{ columnGap: "30px", paddingTop: '0px', paddingBottom: '0px' }}>
              <Flex1>
                <TextLabel>
                  Tipos de Pension
                </TextLabel>
                <Dropdown
                  value={tipoDePension}
                  onValue={(value: any) => setTipoDePension(value)}
                  options={['Sin pensión', 'Con pensión IV', 'Con pensión CV']}
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  Escolaridad
                </TextLabel>
                <Dropdown
                  value={escolaridad}
                  onValue={(value: any) => setEscolaridad(value)}
                  options={['Ninguna', 'Educación Primaria', 'Educación Secundaria', 'Bachillerato', 'Educación Superior', 'Maestría', 'Doctorado']}
                />
              </Flex1>
            </FlexRow>
            <FlexRow style={{ columnGap: "30px", paddingTop: '0px', paddingBottom: '0px' }}>
              <Flex1>
                <TextLabel>
                  Nacionalidad<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={nacionalidad}
                  onChange={(e) => setNacionalidad(e.target.value)}
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  Lugar de Nacimiento<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={lugarDeNacimiento}
                  onChange={(e) => setLugarDeNacimiento(e.target.value)}
                  disabled
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  Fecha de Nacimiento<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={fechaDeNacimiento}
                  onChange={(e) => setFechaDeNacimiento(e.target.value)}
                  disabled
                />
              </Flex1>
            </FlexRow>
            <FlexRow style={{ columnGap: "30px", paddingTop: '0px', paddingBottom: '0px' }}>
              <Flex1>
                <TextLabel>
                  Sexo<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={sexo}
                  onChange={(e) => setSexo(e.target.value)}
                  disabled
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  Estado Civil<Obligatorio>*</Obligatorio>
                </TextLabel>
                <Dropdown
                  value={estadoCivil}
                  onValue={(value: any) => setEstadoCivil(value)}
                  options={['Soltero(a)', 'Casado(a)', 'Viudo(a)', 'Divorciado(a)', 'Unión libre']}
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  Numero del IMSS
                </TextLabel>
                <InputGrande
                  value={numeroDelIMSS}
                  onChange={(e) => setNumeroDelIMSS(e.target.value)}
                />
              </Flex1>
            </FlexRow>
            <FlexRow>
              <Flex1>
                <Checkbox
                  checked={permitirLlenado}
                  handleClick={(value: boolean) => setPermitirLlenado(value)
                  }
                />
                <FlexInlineMarginTextLabel>
                  Permitir llenado por trabajador
                </FlexInlineMarginTextLabel>
              </Flex1>
              {permitirLlenado && <Flex1>
                <Dropdown
                  value={tipoDeLlenado}
                  onValue={(value: any) => setTipoDeLlenado(value)}
                  options={['Marcado de entradas y salidas', 'Manual']}
                />
              </Flex1>}
            </FlexRow>
            <Warning
              type={"Warning"}
              title={"Advertencia"}
              warningSubject={warningfulSubjectLLenado}
            />
          </>)}
      </ContenedorSection>}
      {(((tabOpenDatosPersonales && smallViewPort) || (!smallViewPort && step === 1))) && <ContenedorSection>
        <TextLabelTitle>DOMICILIO</TextLabelTitle>
        <DomicilioFiscal
          ref={domicilioFiscalFormRef}
          onValuesChange={(values: any) => setDomicilioFiscalForm(values)}
          formValues={domicilioFiscalForm}
        />
      </ContenedorSection>}

      {(step === 2 || smallViewPort) && <ContenedorSection>
        <TextLabelTitle>REGISTRO DE BENEFICIARIOS</TextLabelTitle>
        <ChevronTabIcon
          tabOpen={tabOpenBeneficiarios}
          toggleTab={() => setTabOpenBeneficiarios(!tabOpenBeneficiarios)}
        />
        {(tabOpenBeneficiarios || !smallViewPort && step === 2) && (
          <>
            <FlexRow>
              <Flex1>
                <TextLabel>
                  Nombre<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={nombreDelFamiliar}
                  onChange={(e) => setNombreDelFamiliar(e.target.value)}
                />
              </Flex1>
            </FlexRow>
            <FlexRow>
              <Flex1>
                <TextLabel>
                  Parentesco<Obligatorio>*</Obligatorio>
                </TextLabel>
                <Dropdown
                  value={tiposDeParentesco}
                  onValue={(value: any) => setTiposDeParentesco(value)}
                  options={['Cónyuge', 'Hijo', 'Padre', 'Madre', 'Hermano']}
                />
              </Flex1>
            </FlexRow>
            <FlexRow>
              <Flex1>
                <TextLabel>
                  Teléfono<Obligatorio>*</Obligatorio>
                </TextLabel>
                <PhoneInput
                  inputValue={telefonoBeneficiario}
                  onChange={(value: string) => setTelefonoBeneficiario(value)}
                />
              </Flex1>
            </FlexRow>
            <FlexRow>
              <Flex1>
                <TextLabel>
                  Fecha de Nacimiento<Obligatorio>*</Obligatorio>
                </TextLabel>
                <DatePickerComponent
                  pickerWidth={"100%"}
                  value={fechaDeNacimientoBeneficiario}
                  label={""}
                  onValue={(value, isValid) =>
                    setFechaDeNacimientoBeneficiario(isValid ? value : null)
                  }
                />
              </Flex1>
            </FlexRow>
            <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <ButtonPrimario disabled={!nombreDelFamiliar || !telefonoBeneficiario || !fechaDeNacimientoBeneficiario} onClick={() => agregarFamiliar()}>{selectedItemBeneficiario ? 'Editar Familiar' : 'Agregar Familiar'}</ButtonPrimario>
            </div>
          </>)}
      </ContenedorSection>}
      {(((tabOpenBeneficiarios && smallViewPort) || (!smallViewPort && step === 2))) && <ContenedorSection>
        <TextLabelTitle>DOMICILIO</TextLabelTitle>
        {selectedItemBeneficiario && (
          <FlexRow>
            <Flex1>
              <EliminarRow onClick={() => eliminarBeneficiario()}>
                <LabelEliminar>Eliminar beneficiario</LabelEliminar>
                <FontAwesomeIcon icon={faTrashAlt} />
              </EliminarRow>
            </Flex1>
          </FlexRow>
        )}
        <SortingTable
          multiselect={false}
          rowsArray={beneficiariosArray}
          columnsArray={beneficiariosColumnsArray}
          onSelectElement={(element: any) =>
            onSelectBeneficiario(element)
          }
        />
      </ContenedorSection>}

      {(step === 3 || smallViewPort) && <ContenedorSection>
        <TextLabelTitle>CONTRATO</TextLabelTitle>
        <ChevronTabIcon
          tabOpen={tabOpenContrato}
          toggleTab={() => setTabOpenContrato(!tabOpenContrato)}
        />
        {(tabOpenContrato || !smallViewPort && step === 3) && (
          <>
            <FlexRow>
              <Flex1>
                <TextLabel>
                  Departamento<Obligatorio>*</Obligatorio>
                </TextLabel>
                <Dropdown
                  value={departamento}
                  onValue={(value: any) => setDepartamento(value)}
                  options={empresa.empresa.registroDeDepartamentos.departamentos.map((departamento: any) => departamento.nombreDeDepartamento)}
                />
              </Flex1>
              <Flex1>
                <TextLabel>Fecha de Ingreso<Obligatorio>*</Obligatorio>
                </TextLabel>
                <DatePickerComponent
                  pickerWidth={"100%"}
                  value={fechaDeIngreso}
                  label={""}
                  onValue={(value, isValid) =>
                    setFechaDeIngreso(isValid ? value : null)
                  }
                />
              </Flex1>
            </FlexRow>
            <FlexRow>
              <Flex1>
                <TextLabel>
                  Puesto actual<Obligatorio>*</Obligatorio>
                </TextLabel>
                <Dropdown
                  value={puestoActual}
                  onValue={(value: any) => setPuestoActual(value)}
                  options={empresa.trabajadores.registroDePuestos.puestos.map((departamento: any) => departamento.nombreDePuesto)}
                />
              </Flex1>
              <Flex1>
                <TextLabel>Fecha de reconocimiento de antiguedad<Obligatorio>*</Obligatorio>
                </TextLabel>
                <DatePickerComponent
                  pickerWidth={"100%"}
                  value={fechaDeReconocimientoDeAntiguedad}
                  label={""}
                  onValue={(value, isValid) =>
                    setFechaDeReconocimientoDeAntiguedad(isValid ? value : null)
                  }
                />
              </Flex1>
            </FlexRow>
            <Flex1>
              <Checkbox
                checked={puestoDeConfianza}
                handleClick={(value: boolean) => setPuestoDeConfianza(value)
                }
              />
              <FlexInlineMarginTextLabel>
                Puesto de confianza
              </FlexInlineMarginTextLabel>
            </Flex1>
            <Flex1>
              <TextLabel>
                Puesto actual<Obligatorio>*</Obligatorio>
              </TextLabel>
              <Dropdown
                value={tipoDeContrato}
                onValue={(value: any) => setTipoDeContrato(value)}
                options={tipoDeContratoArray}
              />
            </Flex1>
            {(tipoDeContrato !== 'Por tiempo indeterminado' && tipoDeContrato !== 'Para la inversión de capital determinado') && <FlexRow>
              <Flex1>
                <TextLabel>Fecha de Expiración<Obligatorio>*</Obligatorio>
                </TextLabel>
                <DatePickerComponent
                  pickerWidth={"100%"}
                  value={fechaDeExpiracion}
                  label={""}
                  onValue={(value, isValid) =>
                    setFechaDeExpiracion(isValid ? value : null)
                  }
                />
              </Flex1>
            </FlexRow>}
            {(tipoDeContrato === 'Para la inversión de capital determinado') && <FlexRow>
              <Flex1>
                <TextLabel>
                  Monto<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={monto}
                  onChange={(e) => setMonto(e.target.value)}
                />
              </Flex1>
            </FlexRow>}
            {(tipoDeContrato === 'Por tiempo determinado' || tipoDeContrato === 'Por obra determinada' || tipoDeContrato === 'Para la inversión de capital determinado') && <FlexRow>
              <Flex1>
                <TextLabel>
                  Causa que da origen<Obligatorio>*</Obligatorio>
                </TextLabel>
                <TextAreaGrande
                  value={causaQueDaOrigen}
                  onChange={(e) => setCausaQueDaOrigen(e.target.value)}
                />
              </Flex1>
            </FlexRow>}
          </>)}
      </ContenedorSection>}
      {(((tabOpenContrato && smallViewPort) || (!smallViewPort && step === 3))) && <ContenedorSection>
        <TextLabelTitle>HISTORIAL DE BAJAS Y REINGRESOS</TextLabelTitle>
        <SortingTable
          multiselect={false}
          rowsArray={currentTrabajadorCopy?.contrato?.historialDeBajasYReIngresos ? currentTrabajadorCopy.contrato.historialDeBajasYReIngresos : []}
          columnsArray={[
            { label: 'Fecha', valueLabel: 'fecha', filtrable: true, widthPX: 200, type: 'Date' },
            { label: 'Typo', valueLabel: 'typo', filtrable: false, widthPX: 200, type: 'String' },
          ]}
          hideCheckbox={true}
        />
      </ContenedorSection>}

      {(tabOpenHorario || !smallViewPort && step === 4) && <TopContainer>
        <TopArrowsDiv>
          <FontAwesomeIcon icon={faChevronCircleLeft} className={(currentHorrarioIndex > 0 && !justAdded) ? `${styled.faIcon} ${styled.topArrowsIcon}` : `${styled.faIcon} ${styled.topArrowsIconDisabled}`} onClick={() => arrowLeft()} />
          <FontAwesomeIcon icon={faChevronCircleRight} className={(currentTrabajadorCopy?.horario.length - 1 > currentHorrarioIndex) ? `${styled.faIcon} ${styled.topArrowsIcon}` : `${styled.faIcon} ${styled.topArrowsIconDisabled}`} onClick={() => arrowRight()} />
        </TopArrowsDiv>
        <div>
          {eliminarHorarioValid && <ButtonSecundario onClick={() => eliminarUltimoHorarioTrabajador()} disabled={justAdded} style={{marginRight: '10px'}}>Eliminar Horario</ButtonSecundario>}
          {currentTrabajadorCopy && <ButtonPrimario onClick={() => setModalCambioDeHorarioOpen(true)} disabled={justAdded || (currentTrabajadorCopy && currentTrabajadorCopy.horario && currentTrabajadorCopy.horario.length - 1 > currentHorrarioIndex)}>Nuevo Horario</ButtonPrimario>}
        </div>
      </TopContainer>}

      {(step === 4 || smallViewPort) && <ContenedorSection>
        <TextLabelTitle>HORARIO DE TRABAJO</TextLabelTitle>
        <ChevronTabIcon
          tabOpen={tabOpenHorario}
          toggleTab={() => setTabOpenHorario(!tabOpenHorario)}
        />
        {(tabOpenHorario || !smallViewPort && step === 4) && (
          <>
            <TextLabel>
              Usar secuencia de horarios
            </TextLabel>
            <Switch
              checked={usarSecuenciaDeHorarios}
              handleClick={(value: boolean) =>
                setUsarSecuenciaDeHorarios(value)
              }
              disabled={disableHorarios || empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((secuenciaL: any) => !secuenciaL.inactivo).length === 0}
            />
            {!usarSecuenciaDeHorarios && <Flex1>
              <TextLabel>
                Horario<Obligatorio>*</Obligatorio>
              </TextLabel>
              <Dropdown
                disabled={disableHorarios}
                value={horario}
                onValue={(value: any) => onValueHorarioDropdown(value)}
                options={['Sin horario', ...empresa.empresa.horariosYControlDeAsistencia.horarios.filter((horario: any) => !horario.inactivo).map((horario: any) => horario.clave)]}
              />
            </Flex1>}
            {usarSecuenciaDeHorarios && <Flex1>
              <TextLabel>
                Secuencia<Obligatorio>*</Obligatorio>
              </TextLabel>
              <Dropdown
                disabled={disableHorarios}
                value={secuencia}
                onValue={(value: any) => onValueSecuenciaDropdown(value)}
                options={['Sin secuencia', ...empresa.empresa.horariosYControlDeAsistencia.secuencias.filter((secuencia: any) => !secuencia.inactivo).map((secuencia: any) => secuencia.clave)]}
              />
            </Flex1>}
            <Flex1>
              <Checkbox
                disabled={disableHorarios}
                checked={considerarTiempoLaboradoEnHorarioDeComida}
                handleClick={(value: boolean) => setConsiderarTiempoLaboradoEnHorarioDeComida(value)
                }
              />
              <FlexInlineMarginTextLabel style={{ paddingBottom: '0px' }}>
                Considerar tiempo laborado en horario de comida
              </FlexInlineMarginTextLabel>
            </Flex1>
            <Flex1>
              <Checkbox
                disabled={disableHorarios}
                checked={considerarEntradasAnticipadasComoTiempoExtra}
                handleClick={(value: boolean) => setConsiderarEntradasAnticipadasComoTiempoExtra(value)
                }
              />
              <FlexInlineMarginTextLabel style={{ padding: '0px' }}>
                Considerar entradas anticipadas como tiempo extra
              </FlexInlineMarginTextLabel>
            </Flex1>
            <Flex1>
              <Checkbox
                disabled={disableHorarios}
                checked={pagarHorasExtra}
                handleClick={(value: boolean) => setPagarHorasExtra(value)
                }
              />
              <FlexInlineMarginTextLabel style={{ padding: '0px' }}>
                Pagar horas extra
              </FlexInlineMarginTextLabel>
            </Flex1>
          </>)}

        {(tabOpenHorario || !smallViewPort && step === 4) && !usarSecuenciaDeHorarios && secuenciaDeEntradasYSalidasH && ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'].filter((key: any) => secuenciaDeEntradasYSalidasH[key] && secuenciaDeEntradasYSalidasH[key].length).length > 0 && <TextLabel>Omitir los siguientes datos del horario al importar registros del reloj checador</TextLabel>}
        {(tabOpenHorario || !smallViewPort && step === 4) && !usarSecuenciaDeHorarios && secuenciaDeEntradasYSalidasH && ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'].map((key: any) => secuenciaDeEntradasYSalidasH[key] && secuenciaDeEntradasYSalidasH[key].length ? (
          <>
            <FlexRow key={key + 'Title'}><TextLabel style={{ padding: '5px 0px', textTransform: 'capitalize', fontWeight: 'bold' }}>{key}</TextLabel></FlexRow>
            {secuenciaDeEntradasYSalidasH[key].map((horario: any, index: number) => (
              <>
                <FlexRow key={key + 'entrada' + index} style={{ padding: '0px' }}>
                  <Flex1>
                    <Checkbox
                      checked={horario.omitirEntrada}
                      handleClick={(value: boolean) => {
                        const secuenciaDeEntradasYSalidasHCopy = { ...secuenciaDeEntradasYSalidasH };
                        secuenciaDeEntradasYSalidasHCopy[key][index].omitirEntrada = value;
                        setSecuenciaDeEntradasYSalidasH({ ...secuenciaDeEntradasYSalidasHCopy })
                      }}
                      disabled={disableHorarios}
                    />
                    <FlexInlineMarginTextLabel style={{ padding: '0px' }}>Entrada {presentarNumero(horario.entrada)}</FlexInlineMarginTextLabel>
                  </Flex1>
                </FlexRow>
                <FlexRow key={key + 'salida'} style={{ padding: '0px' }}>
                  <Flex1>
                    <Checkbox
                      checked={horario.omitirSalida}
                      handleClick={(value: boolean) => {
                        const secuenciaDeEntradasYSalidasHCopy = { ...secuenciaDeEntradasYSalidasH };
                        secuenciaDeEntradasYSalidasHCopy[key][index].omitirSalida = value;
                        setSecuenciaDeEntradasYSalidasH({ ...secuenciaDeEntradasYSalidasHCopy })
                      }}
                      disabled={disableHorarios}
                    />
                    <FlexInlineMarginTextLabel style={{ padding: '0px' }}>Salida {presentarNumero(horario.salida)}</FlexInlineMarginTextLabel>
                  </Flex1>
                </FlexRow>
              </>
            ))}
          </>
        ) : null)}

        {(tabOpenHorario || !smallViewPort && step === 4) && usarSecuenciaDeHorarios && secuenciaDeEntradasYSalidasS && secuenciaDeEntradasYSalidasS.length > 0 && secuenciaDeEntradasYSalidasS.filter((secuencia: any) => ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'].filter((key: any) => secuencia[key] && secuencia[key].length).length > 0) && <TextLabel style={{ paddingBottom: '0px' }}>Omitir los siguientes datos del horario al importar registros del reloj checador</TextLabel>}
        {(tabOpenHorario || !smallViewPort && step === 4) && usarSecuenciaDeHorarios && secuenciaDeEntradasYSalidasS &&  secuenciaDeEntradasYSalidasS.length > 0 && secuenciaDeEntradasYSalidasS.map((secuencia: any, index: number) => (
          <>
            {['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'].filter((key: any) => secuencia[key] && secuencia[key].length).length > 0 && <Flex1 style={{ paddingTop: '20px', paddingBottom: '10px' }} key={index + 'Title'}><TextLabel style={{ padding: '0px', textTransform: 'capitalize', fontWeight: 'bold' }}><FontAwesomeIcon style={{ marginRight: '10px' }} icon={faClock} />{secuencia.horario}</TextLabel></Flex1>}
            {['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'].map((key: any) => secuencia[key] && secuencia[key].length ? (
              <>
                <FlexRow key={key + 'Title'}><TextLabel style={{ padding: '5px 0px', textTransform: 'capitalize', fontWeight: 'bold' }}>{key}</TextLabel></FlexRow>
                {secuencia[key].map((horario: any, index2: number) => (
                  <>
                    <FlexRow key={key + 'entrada'} style={{ padding: '0px' }}>
                      <Flex1>
                        <Checkbox
                          checked={horario.omitirEntrada}
                          handleClick={(value: boolean) => {
                            const secuenciaDeEntradasYSalidasSCopy = [...secuenciaDeEntradasYSalidasS];
                            secuenciaDeEntradasYSalidasSCopy[index][key][index2].omitirEntrada = value;
                            setSecuenciaDeEntradasYSalidasS([...secuenciaDeEntradasYSalidasSCopy])
                          }}
                          disabled={disableHorarios}
                        />
                        <FlexInlineMarginTextLabel style={{ padding: '0px' }}>Entrada {presentarNumero(horario.entrada)}</FlexInlineMarginTextLabel>
                      </Flex1>
                    </FlexRow>
                    <FlexRow key={key + 'salida'} style={{ padding: '0px' }}>
                      <Flex1>
                        <Checkbox
                          checked={horario.omitirSalida}
                          handleClick={(value: boolean) => {
                            const secuenciaDeEntradasYSalidasSCopy = [...secuenciaDeEntradasYSalidasS];
                            secuenciaDeEntradasYSalidasSCopy[index][key][index2].omitirSalida = value;
                            setSecuenciaDeEntradasYSalidasS([...secuenciaDeEntradasYSalidasSCopy])
                          }}
                          disabled={disableHorarios}
                        />
                        <FlexInlineMarginTextLabel style={{ padding: '0px' }}>Salida {presentarNumero(horario.salida)}</FlexInlineMarginTextLabel>
                      </Flex1>
                    </FlexRow>
                  </>
                ))}
              </>
            ) : null)}
          </>
        ))}
        {(tabOpenHorario || !smallViewPort && step === 4) && usarHorarioEspecial && !usarSecuenciaDeHorarios && <>
          <Flex1>
            <TextLabel>
              Fecha de inicio del horario<Obligatorio>*</Obligatorio>
            </TextLabel>
            <DatePickerComponent
              pickerWidth={"100%"}
              value={fechaDeInicioEspecial}
              label={""}
              disabled={disableHorarios}
              onValue={(value, isValid) =>
                setFechaDeInicioEspecial(isValid ? value : null)
              }
              filterFunction={(d: any) => {
                const date = new Date(d);
                return selectedDayModalCambioDeHorario >= date;
              }}
            />
          </Flex1>
          <Flex1>
            <TextLabel>
              Hora de inicio<Obligatorio>*</Obligatorio>
            </TextLabel>
            <InputMini
              disabled={disableHorarios}
              value={horaDeInicio}
              onBlur={(e: any) => setHoraDeInicio(ccHoursBlur(e.target.value))}
              onChange={(e: any) => setHoraDeInicio(ccHoursChange(e.target.value))}
              placeholder="00:00"
              type="text"
            />
          </Flex1>
          <Flex1>
            <TextLabel>
              Inicio de ciclo<Obligatorio>*</Obligatorio>
            </TextLabel>
            <Dropdown
              value={cicloDeInicioEspecial}
              onValue={(value: any) => setCicloDeInicioEspecial(value)}
              options={arregloDeCiclosEspecial}
              disabled={disableHorarios}
            />
          </Flex1>
        </>}
        {(tabOpenHorario || !smallViewPort && step === 4) && <FlexRow style={{ justifyContent: "flex-start", alignItems: 'flex-start', paddingBottom: '0px' }}>
          <TextLabel style={{ width: 'fit-content' }}>Tiempo x tiempo pendiente</TextLabel>
          <InputMini
            value={presentarNumero(tiempoXTiempo)}
            disabled={true}
            onBlur={(e: any) => setTiempoXTiempo(ccHoursBlur(e.target.value))}
            onChange={(e: any) => setTiempoXTiempo(ccHoursChange(e.target.value))}
            placeholder="00:00"
            type="number"
          />
        </FlexRow>}
        {currentTrabajadorCopy?.horario[currentHorrarioIndex]?.fechaDeFinalizacion && <p>Ultimo dia utilizado: <span style={{color: 'red'}}>{formatDay(new Date(currentTrabajadorCopy?.horario[currentHorrarioIndex]?.fechaDeFinalizacion))}</span></p>}
      </ContenedorSection>}


      <DivNominaTopContainer>
        <DivNominaTop>
          <DivNominaTopLeft>
            {(step === 5 || smallViewPort) && <ContenedorSectionInner>
              <TextLabelTitle>NOMINA</TextLabelTitle>
              <ChevronTabIcon
                tabOpen={tabOpenNomina}
                toggleTab={() => setTabOpenNomina(!tabOpenNomina)}
              />
              {(tabOpenNomina || !smallViewPort && step === 5) && (
                <>
                  <Flex1>
                    <TextLabel>
                      Forma de pago<Obligatorio>*</Obligatorio>
                    </TextLabel>
                    <Dropdown
                      value={formaDePago}
                      onValue={(value: any) => setFormaDePago(value)}
                      options={['Efectivo', 'Cheque', 'Transferencia', ...bancoDespensaArray]}
                    />
                  </Flex1>
                  <Flex1>
                    <TextLabel>
                      Cuenta
                    </TextLabel>
                    <InputGrande
                      value={formaDePagoCuenta}
                      onChange={(e) => setFormaDePagoCuenta(e.target.value)}
                    />
                  </Flex1>
                  {bancoDespensaArray.length > 0 && (<Flex1>
                    <TextLabel>
                      Banco despensa
                    </TextLabel>
                    <Dropdown
                      value={bancoDespensa}
                      onValue={(value: any) => setBancoDespensa(value)}
                      options={bancoDespensaArray}
                    />
                  </Flex1>)
                  }
                  {bancoDespensaArray.length > 0 && (<Flex1>
                    <TextLabel>
                      Tarjeta de Despensa
                    </TextLabel>
                    <InputGrande
                      value={tarjetaDespensa}
                      onChange={(e) => setTarjetaDespensa(e.target.value)}
                    />
                  </Flex1>)
                  }
                  <Flex1>
                    <TextLabel>
                      Esquema de Nomina<Obligatorio>*</Obligatorio>
                    </TextLabel>
                    <Dropdown
                      value={esquemaDeNomina}
                      onValue={(value: any) => setEsquemaDeNomina(value)}
                      options={esquemaDeNominaArray}
                    />
                  </Flex1>
                  {siguenteDiaAPagarActive && <Flex1>
                    <TextLabel>
                      Siguiente día a pagar<Obligatorio>*</Obligatorio>
                    </TextLabel>
                    <DatePickerComponent
                      pickerWidth={"100%"}
                      value={siguenteDiaAPagar}
                      label={""}
                      onValue={(value, isValid) =>
                        setSiguenteDiaAPagar(isValid ? value : null)
                      }
                    />
                  </Flex1>}
                  <Flex1>
                    <TextLabel>
                      Zona<Obligatorio>*</Obligatorio>
                    </TextLabel>
                    <Dropdown
                      value={zona}
                      onValue={(value: any) => setZona(value)}
                      options={['General', 'Zona Libre de la Frontera Norte']}
                    />
                  </Flex1>
                </>)}
            </ContenedorSectionInner>}
          </DivNominaTopLeft>
          {(tabOpenNomina || !smallViewPort && step === 5) && <DivNominaTopRight>
            <ContenedorSectionInner>
              <TextLabelTitle>VACACIONES</TextLabelTitle>
              <TextLabel>Días pendientes de disfrutar</TextLabel>
              <QuantityManager
                Minimum={0}
                quantity={diasPendientesDeDisfrutar}
                Maximum={9999}
                height={43}
                onValue={(value: number) => setDiasPendientesDeDisfrutar(value)}
                disabled={false} />
            </ContenedorSectionInner>

            <ContenedorSectionInner>
              <TextLabelTitle>INFONAVIT</TextLabelTitle>
              <Flex1>
                <TextLabel>
                  No. Crédito
                </TextLabel>
                <InputGrande
                  value={creditoInfonavit}
                  onChange={(e) => setCreditoInfonavit(e.target.value)}
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  Fecha
                </TextLabel>
                <DatePickerComponent
                  pickerWidth={"100%"}
                  value={fechaInfonavit}
                  label={""}
                  onValue={(value, isValid) =>
                    setFechaInfonavit(isValid ? value : null)
                  }
                />
              </Flex1>
              <Flex1>
                <TextLabel>
                  Descuento
                </TextLabel>
                <Dropdown
                  value={descuento}
                  onValue={(value: any) => setDescuento(value)}
                  options={['Ninguno', 'Porcentaje', 'Cuota fija (en pesos)', 'Veces Salario Mínimo (VSM) (UMI)']}
                />
              </Flex1>
              {descuento === 'Porcentaje' && <Flex1>
                <TextLabel>
                  Porcentaje
                </TextLabel>
                <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><InputGrande
                  value={descuentoPorcentaje}
                  onChange={(e) => setDescuentoPorcentaje(ccNumberLimit(parseInt(e.target.value), 100))}
                  type="number"
                /> %
                </span>
              </Flex1>}
              {descuento === 'Cuota fija (en pesos)' && <Flex1>
                <TextLabel>
                  Cuota Fija
                </TextLabel>
                <InputGrande
                  value={descuentoCuotaFija}
                  onChange={(e) => setDescuentoCuotaFija(e.target.value)}
                  type="number"
                />
              </Flex1>}
              {descuento === 'Veces Salario Mínimo (VSM) (UMI)' && <Flex1>
                <TextLabel>
                  Veces Salario Mínimo
                </TextLabel>
                <InputGrande
                  value={descuentoVSM}
                  onChange={(e) => setDescuentoVSM(e.target.value)}
                  type="number"
                />
              </Flex1>}
            </ContenedorSectionInner>

            <ContenedorSectionInner>
              <Flex1>
                <Checkbox
                  checked={omitirSubsidio}
                  handleClick={(value: boolean) => {
                    setOmitirSubsidio(value)
                  }}
                />
                <FlexInlineMarginTextLabel style={{ padding: '0px' }}>Omitir Subsidio para el Empleo en el cálculo del ISPT</FlexInlineMarginTextLabel>
              </Flex1>
            </ContenedorSectionInner>
            <ContenedorSectionInner>
              <Flex1 style={{ justifyContent: 'center', gap: '15px', display: 'flex' }}>
                <SiglasDiv><b>UMA:</b> {informacionGeneral.uma} </SiglasDiv>
                <SiglasDiv><b>SMG:</b> {informacionGeneral.smg} </SiglasDiv>
                <SiglasDiv><b>SMF:</b> {informacionGeneral.smf} </SiglasDiv>
                <SiglasDiv><b>UMI:</b> {informacionGeneral.umi}</SiglasDiv>
              </Flex1>
            </ContenedorSectionInner>

          </DivNominaTopRight>}
        </DivNominaTop>
      </DivNominaTopContainer>

      {(tabOpenNomina || !smallViewPort && step === 5) && <NominaContenedorTabs>
        <Tabs
          onValue={(tab: string) => setActiveTabNomina(tab)}
          Tabs={[
            { label: "ACTUAL", enable: true },
            { label: "HISTORIAL COMPLETO", enable: true },
            { label: "VARIABLES IMSS", enable: true },
          ]}
        />
        <ContenedorNominaInferior>
          {activeTabNomina === "ACTUAL" && <>
            <TextLabelTitle>CUOATA DIARIA</TextLabelTitle>
            <FlexRow>
              <Flex3>
                <TextLabel>
                  Cuota<Obligatorio>*</Obligatorio>
                </TextLabel>
                <InputGrande
                  value={cuotaDiaria}
                  onChange={(e) => setCuotaDiaria(e.target.value)}
                  type="number"
                />
              </Flex3>
              <Flex3></Flex3>
              <Flex3></Flex3>
            </FlexRow>
            <TextLabelTitle>SALARIO BASE DE COTIZACIÓN</TextLabelTitle>
            <FlexRow>
              <SideButtonContainer>
                <div>
                  <TextLabel>
                    Salario<Obligatorio>*</Obligatorio>
                  </TextLabel>
                  <InputGrande
                    value={salarioBase}
                    onChange={(e) => setSalarioBase(e.target.value)}
                    type="number"
                  />
                </div>
                <ButtonPrimario onClick={() => setModalSalarioDetalles(true)} disabled={!activeButtonNomina}>Desglosar</ButtonPrimario>
              </SideButtonContainer>
            </FlexRow>
            <FlexRow>
              <Flex1>
                <TextLabel>
                  Fecha<Obligatorio>*</Obligatorio>
                </TextLabel>
                <DatePickerComponent
                  pickerWidth={"100%"}
                  value={salarioBaseFecha}
                  label={""}
                  onValue={(value, isValid) =>
                    setSalarioBaseFecha(isValid ? value : null)
                  }
                />
              </Flex1>
            </FlexRow>
          </>}
          {activeTabNomina === "HISTORIAL COMPLETO" && <>
            <SortingTable
              hideCheckbox={true}
              rowsArray={historialCompletoArray}
              columnsArray={historialCompletoColumnsArray}
              tableHeight="460px"
            />
          </>}
          {activeTabNomina === "VARIABLES IMSS" && <>
            <SortingTable
              hideCheckbox={true}
              rowsArray={variablesIMSSArray}
              columnsArray={variablesIMSSColumnsArray}
              tableHeight="460px"
            />
          </>}
        </ContenedorNominaInferior>
      </NominaContenedorTabs>}

      <ContenedorBotonesExterior>
        <ContenedorBotones>
          <ButtonPrimario onClick={() => submitForm()}>{currentTrabajadorCopy ? 'Guardar Cambios' : (step === 5 || smallViewPort) ? 'Agregar' : 'Siguiente'}</ButtonPrimario>
          <ButtonSecundario onClick={() => cancel()}>{currentTrabajadorCopy ? 'Salir sin guardar' : 'Cancelar'}</ButtonSecundario>
        </ContenedorBotones>
      </ContenedorBotonesExterior>
      <Modal show={modalSalarioDetalles}>
        <Contenedor>
          <Titulo>DESGLOCE DE SALARIO RECOMENDADO</Titulo>
          {arregloDesgloceSalario.length > 0 && <div className={styled.ContenedorRows}>
            {arregloDesgloceSalario.map((item: any) => (
              <div className={item.type === 'Normal' ? styled.Normal : item.type === 'Bold' ? styled.Bold : styled.Result} >
                <p>{item.label}</p>
                <p>{item.value}</p>
              </div>
            ))}
          </div>}

          <ButtonDiv>
            <ButtonSecundario
              onClick={() => setModalSalarioDetalles(false)}
            >
              Salir
            </ButtonSecundario>
          </ButtonDiv>
        </Contenedor>
      </Modal>
      <Modal show={modalCambioDeHorarioOpen}>
        <Contenedor>
          <Titulo>FINALIZAR HORARIO</Titulo>
          <TextDiv>
            <TextoNormal>
              Ingrese el ultimo dia en el cual aplicara el horario actual.
            </TextoNormal>
            <DatePickerComponent
              pickerWidth={"100%"}
              value={selectedDayModalCambioDeHorario}
              label={""}
              filterFunction={(d: any) => {
                const date = new Date(d);
                return new Date(ultimaFecha) >= date;
              }}
              onValue={(value, isValid) =>
                setSelectedDayModalCambioDeHorario(isValid ? value : null)
              }
            />
          </TextDiv>

          <ButtonDiv>
            <ButtonPrimario
              onClick={() => addEndDate()}
              disabled={!selectedDayModalCambioDeHorario && !isNaN(new Date(selectedDayModalCambioDeHorario).getTime())}
            >
              Aceptar
            </ButtonPrimario>
            <ButtonSecundario
              onClick={() => cancelEndDate()}
            >
              Cancelar
            </ButtonSecundario>
          </ButtonDiv>
        </Contenedor>
      </Modal>
    </div>
  )
}

